<template>
    <table class="table-tree">
        <thead>
            <th
                v-for="(field, i) in fields"
                :key="i"
            >{{field.label}}</th>
        </thead>
        <tbody>
            <tr>
                <td>
                    <b-button
                        class="btn-action-dimensions"
                        :class="{
                            'visible': node.hasOwnProperty('items') && node.items.length > 0
                        }"
                        style="border: none !important; padding: 0 !important;"
                        variant="outline-primary"
                        size="sm"
                        @click="toggleDetails(node)"
                    >
                        <b-icon v-if="!node.detailsShowing" icon="caret-down" aria-hidden="true"></b-icon>
                        <b-icon v-if="node.detailsShowing" icon="caret-up" aria-hidden="true"></b-icon>
                    </b-button>
                </td>
                <td
                    v-for="(field, i) in fields.filter((el, i) => i > 0)"
                    :key="i"
                >
                    {{ node[field.key] }}
                </td>
            </tr>
            <tr>
                <td :colspan="fields.length" v-if="node.items && node.items.length > 0">
                    <node
                        v-show="node.detailsShowing"
                        v-for="(child, i) in node.items"
                        :key="i"
                        :node="child"
                        :fields="fields"
                    ></node>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
/* eslint-disable no-debugger */
export default {
    name: "node",
    props: {
        node: Object,
        fields: Array
    },
    created () {
        // debugger
    },
    methods: {
        toggleDetails (node) {
            if (node.detailsShowing) {
                node.detailsShowing = !node.detailsShowing
            } else {
                node.detailsShowing = true
            }
            this.$forceUpdate()
        }
    }
};
</script>