<template>
    <div class="d3-tree"></div>
</template>

<script>
import d3 from 'd3'
window.d3 = d3
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
export default {
    name: "d3-tree",
    props: {
        items: Array,
        fields: Array
    },
    data () {
        return {
            treeData: []
        }
    },
    updated () {
        console.log('updated')
        this.$forceUpdate()
    },
    created () {
        if (this.items.length > 0) {
            this.$nextTick(() => {
                this.treeData = this.modifyArray(this.items)
                this.$nextTick(() => {
                    // debugger
    
                    this.init()
                })
            })
        }
    },
    methods: {
        modifyArray (arr, parent = 'null') {
            return arr.map(obj => {
                let props = {}

                Object.keys(obj).forEach((key) => {
                    if (key !== 'items' && key !== '_showDetails') {
                        props[key] = obj[key]
                    }
                })

                if (obj.items) {
                    return {
                        name: obj.Concept,
                        props,
                        parent,
                        children: this.modifyArray(obj.items, obj.Concept)
                    }
                } else {
                    return {
                        name: obj.Concept,
                        props,
                        parent
                    }
                }
            })
        },
        init () {
            console.log(this.treeData)

            function wrap() {
                var self = d3.select(this),
                    textWidth = self.node().getComputedTextLength(),    // Width of text in pixel.
                    initialText = self.text(),                          // Initial text.
                    textLength = initialText.length,                    // Length of text in characters.
                    text = initialText,
                    precision = 10, //textWidth / width,                // Adjustable precision.
                    maxIterations = 100; // width;                      // Set iterations limit.

                while (maxIterations > 0 && text.length > 0 && Math.abs(width - textWidth) > precision) {

                    text = /*text.slice(0,-1); =*/(textWidth >= width) ? text.slice(0, -textLength * 0.15) : initialText.slice(0, textLength * 1.15);
                    self.text(text + '...');
                    textWidth = self.node().getComputedTextLength();
                    textLength = text.length;
                    maxIterations--;
                }
                console.log(width - textWidth);
            }

            var margin = {top: 20, right: 120, bottom: 20, left: 300},
                width = 960 - margin.right - margin.left,
                height = 600 - margin.top - margin.bottom;
                
            var i = 0,
                duration = 750,
                root;

            var tree = d3.layout.tree()
                .size([height, width]);

            var diagonal = d3.svg.diagonal()
                .projection(function(d) { return [d.y, d.x]; });

            var svg = d3.select(".d3-tree").append("svg")
                // .attr("width", width + margin.right + margin.left)
                // .attr("height", height + margin.top + margin.bottom)
                .attr("width", '100%')
                .attr("height", '100%')
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            // Add tooltip div
            var div = d3.select(".d3-tree").append("div")
                .attr("class", "tooltip")
                .style("opacity", 1e-6);

            console.log(svg)
            // debugger

            this.treeData = [{
                name: "null",
                children: this.treeData
            }];

            root = this.treeData[0];
            root.x0 = height / 2;
            root.y0 = 0;
            
            update(root);

            d3.select(".d3-tree").style("height", "600px");

            function update(source) {
                // Compute the new tree layout.
                var nodes = tree.nodes(root).reverse(),
                    links = tree.links(nodes);

                // Normalize for fixed-depth.
                nodes.forEach(function(d) { d.y = d.depth * 180; });

                // Update the nodes…
                var node = svg.selectAll("g.node")
                    .data(nodes, function(d) { return d.id || (d.id = ++i); });

                function mouseover() {
                    div.transition()
                        .duration(300)
                        .style("opacity", 1);
                }

                function mousemove(d) {
                    div
                        .html(() => {
                            // console.log(d.props)
                            let html = ''
                            if (Object.keys(d.props).length > 0) {
                                Object.keys(d.props).forEach((key) => {
                                    html += `<div class="tooltip__row"><span class="tooltip__key">${key}:</span><span class="tooltip__value">${d.props[key]}</span></div>`
                                })
                            }
                            return html
                        })
                        .style("left", (d3.event.pageX ) + "px")
                        .style("top", (d3.event.pageY) + "px");
                }

                function mouseout() {
                    div.transition()
                        .duration(300)
                        .style("opacity", 1e-6);
                }

                // Enter any new nodes at the parent's previous position.
                var nodeEnter = node.enter().append("g")
                    .attr("class", function(d) {
                        let className = `node level-${d.depth}`

                        if (d.props && d.props.hasOwnProperty('CalculationPolarity')) {
                            className += ` node-${d.props.CalculationPolarity}`
                        }
                        return className
                    })
                    .attr("transform", function(d) { return "translate(" + source.y0 + "," + source.x0 + ")"; })
                    .on("click", click);

                nodeEnter.append("circle")
                    .attr("r", 1e-6)
                    .style("fill", function(d) { return d._children ? "#0A58CA" : "#fff"; });

                nodeEnter.append("text")
                    .attr("x", function(d) { return d.children || d._children ? -13 : 13; })
                    .attr("dy", ".35em")
                    .attr("text-anchor", function(d) { return d.children || d._children ? "end" : "start"; })
                    .text(function(d) { return d.name; })
                    .style("fill-opacity", 1e-6)
                    // .each(wrap);

                // Add the dot at every node
                nodeEnter.append("svg:circle")
                    .on("mouseover", mouseover)
                    .on("mousemove", function(d){mousemove(d);})
                    .on("mouseout", mouseout)
                    .attr("fill-opacity", "0.1")
                    .attr("r", 10);

                // Transition nodes to their new position.
                var nodeUpdate = node.transition()
                    .duration(duration)
                    .attr("transform", function(d) { return "translate(" + d.y + "," + d.x + ")"; });

                nodeUpdate.select("circle")
                    .attr("r", 10)
                    .style("fill", function(d) {
                        let fill = d._children ? "#0A58CA" : "#fff";
                        // if (d.props && d.props.hasOwnProperty('CalculationPolarity')) {
                        //     fill = d.props.CalculationPolarity === 'Add' ? "green" : "red";
                        // }
                        return fill
                    });

                nodeUpdate.select("text")
                    .style("fill-opacity", 1);

                // Transition exiting nodes to the parent's new position.
                var nodeExit = node.exit().transition()
                    .duration(duration)
                    .attr("transform", function(d) { return "translate(" + source.y + "," + source.x + ")"; })
                    .remove();

                nodeExit.select("circle")
                    .attr("r", 1e-6);

                nodeExit.select("text")
                    .style("fill-opacity", 1e-6);

                // Update the links…
                var link = svg.selectAll("path.link")
                    .data(links, function(d) { return d.target.id; });

                // Enter any new links at the parent's previous position.
                link.enter().insert("path", "g")
                    .attr("class", function(d) {
                        let className = `link level-${d.source.depth}`
                        console.log(className)
                        if (d.target.props && d.target.props.hasOwnProperty('CalculationPolarity')) {
                            className += ` path-${d.target.props.CalculationPolarity}`
                        }
                        return className
                    })
                    .attr("d", function(d) {
                        var o = {x: source.x0, y: source.y0};
                        return diagonal({source: o, target: o});
                    });

                // Transition links to their new position.
                link.transition()
                    .duration(duration)
                    .attr("d", diagonal);

                // Transition exiting nodes to the parent's new position.
                link.exit().transition()
                    .duration(duration)
                    .attr("d", function(d) {
                        var o = {x: source.x, y: source.y};
                        return diagonal({source: o, target: o});
                    })
                    .remove();

                // Stash the old positions for transition.
                nodes.forEach(function(d) {
                    d.x0 = d.x;
                    d.y0 = d.y;
                });
            }

            // Toggle children on click.
            function click(d) {
                if (d.children) {
                    d._children = d.children;
                    d.children = null;
                } else {
                    d.children = d._children;
                    d._children = null;
                }
                update(d);
            }
        }
    }
};
</script>

<style lang="scss">
.d3-tree {
    margin-bottom: 24px;

    .node {
        cursor: pointer;
    }

    .node circle {
        fill: #fff;
        stroke: #0A58CA;
        stroke-width: 3px;
    }

    .node text {
        font: 12px sans-serif;
    }

    .link {
        fill: none;
        stroke: #ccc;
        stroke-width: 2px;
    }

    .tooltip {
        position: absolute;
        text-align: center;
        max-width: 600px;
        color: #fff;
        font-weight: 400;
        padding: 12px;
        font-size: 12px;
        background: #084298;
        border: solid 1px #aaa;
        border-radius: 8px;
        pointer-events: none;
        height: auto;
    }

    .level-0 {
        opacity: 0;
    }

    .node-Add circle,
    .path-Add {
        stroke: #c22222;
        // stroke: #0f8e0f;
    }

    .node-Substract circle,
    .path-Substract {
        // stroke: #c22222;
    }

}
</style>