<template>
    <div class="esef-wrapper">
        <div class="esef" v-if="this.reportID">
            <div class="preloader" v-if="fileIsLodaing">
                <b-spinner label="Spinning"></b-spinner>
            </div>
            <div class="esef__left">
                <iframe width="1024" height="768" sandbox="allow-same-origin allow-scripts" seamless id="fileFrame" src="about:blank" ref="fileFrame"></iframe>
                <div class="zoom">
                    <b-icon @click="zoomIn()" icon="zoom-in" aria-hidden="true"></b-icon>
                    <b-icon @click="zoomOut()" icon="zoom-out" aria-hidden="true"></b-icon>
                    <b-icon @click="zoomReset()" icon="x-circle" aria-hidden="true"></b-icon>
                </div>
            </div>
            <div class="esef__right">
                <b-card no-body>
                    <div class="esef__global-actions">
                        <b-form-file
                            v-model="file"
                            @input="onInputFile"
                            placeholder="Upload a report or drop it here..."
                            drop-placeholder="Drop report here..."
                        ></b-form-file>
                        <div class="cloud-btn-group">
                            <b-button
                                class="cloud-btn-group__save"
                                variant="primary"
                                title="Save"
                                v-b-tooltip.hover
                                @click="cloudUploadReport('post')"
                            ><b-icon icon="cloud-upload" aria-hidden="true"></b-icon><span>Save</span></b-button>

                            <b-button
                                variant="primary"
                                v-b-tooltip.hover
                                title="Download"
                                @click="downloadCurrentState()"
                            ><b-icon icon="cloud-download" aria-hidden="true"></b-icon></b-button>

                            <b-button
                                variant="danger"
                                v-b-tooltip.hover
                                title="Remove"
                                @click="cloudRemoveReport()"
                            ><b-icon icon="cloud-slash" aria-hidden="true"></b-icon></b-button>
                        </div>
                    </div>
                    <b-tabs card v-model="activeTab">
                        <b-tab title="Add">
                            <b-card-text>
                                <div class="esef-add">
                                    <b-form-group
                                        id="select-table"
                                        label="Select table name:"
                                        label-for="select-table"
                                        >
                                        <v-select
                                            id="select-table"
                                            v-model="selectedTableName"
                                            :options="tableNames"
                                            @input="onChangeTable()"
                                            label="text"
                                            :clearable="false"
                                            :searchable="false"
                                            placeholder="-- Select table name --"
                                        ></v-select>
                                    </b-form-group>

                                    <b-list-group v-if="tags.length">
                                        <b-list-group-item
                                            v-for="(tag, i) in tags"
                                            :key="i"
                                            button
                                            :active="tagsIdsInFrame.includes(tag.id)"
                                            @click="onChangeTag(tag)"
                                        >
                                            <div class="esef-row">
                                                <div class="esef-label">Name:</div>
                                                <div class="esef-value">{{tag.text}}</div>
                                            </div>
                                            <div class="esef-row">
                                                <div class="esef-label">Context:</div>
                                                <div class="esef-value">{{tag.context}}</div>
                                            </div>
                                            <div class="esef-row">
                                                <div class="esef-label">Indicative value:</div>
                                                <div class="esef-value">{{tag.indicativeValue}}</div>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                            </b-card-text>
                        </b-tab>
                        <b-tab title="Edit" v-if="selectedEditableTag">
                            <b-card-text>
                                <div class="esef-edit">
                                    <div class="esef-row">
                                        <div class="esef-value">
                                            <b-form-group
                                                label="Name"
                                            >
                                                <b-form-input
                                                    v-model="selectedEditableTag.name"
                                                    disabled
                                                    type="text"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Context"
                                            >
                                                <b-form-textarea
                                                    v-model="selectedEditableTag.contextref"
                                                ></b-form-textarea>
                                            </b-form-group>
                                            <b-form-group
                                                label="Value"
                                            >
                                                <b-form-input
                                                    v-model="selectedEditableTag.value"
                                                    type="text"
                                                    disabled
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                v-if="selectedEditableTag.type === 'monetary'"
                                                label="Sign minus"
                                            >
                                                <b-form-checkbox
                                                    id="checkbox-1"
                                                    v-model="statusSignMinus"
                                                    name="checkbox-1"
                                                >
                                                </b-form-checkbox>
                                            </b-form-group>
                                            <b-form-group
                                                v-if="selectedEditableTag.type === 'monetary'"
                                                label="Unit"
                                            >
                                                <b-form-input
                                                    v-model="selectedEditableTag.unitref"
                                                    type="text"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                v-if="selectedEditableTag.type === 'monetary'"
                                                label="Decimals"
                                            >
                                                <b-form-input
                                                    v-model="selectedEditableTag.decimals"
                                                    type="number"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                v-if="selectedEditableTag.type === 'monetary'"
                                                label="Scale"
                                            >
                                                <b-form-input
                                                    v-model="selectedEditableTag.scale"
                                                    type="number"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                v-if="selectedEditableTag.type === 'monetary'"
                                                id="edit-format"
                                                label="Format"
                                                label-for="edit-format"
                                                >
                                                <v-select
                                                    id="edit-format"
                                                    v-model="selectedEditableTag.format"
                                                    :reduce="el => el.value"
                                                    :options="editFormats"
                                                    label="text"
                                                    :clearable="false"
                                                    :searchable="false"
                                                    placeholder="-- Select format --"
                                                ></v-select>
                                            </b-form-group>
                                            <b-form-group
                                                v-if="selectedEditableTag.type === 'text'"
                                                label="xml:lang"
                                            >
                                                <b-form-input
                                                    v-model="selectedEditableTag['xml:lang']"
                                                    type="text"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                v-if="selectedEditableTag.type === 'text'"
                                                label="Escape"
                                            >
                                                <b-form-checkbox
                                                    id="checkbox-escape"
                                                    v-model="selectedEditableTag.escape"
                                                    name="checkbox-escape"
                                                >
                                                </b-form-checkbox>
                                            </b-form-group>
                                            <b-form-group
                                                label="Fact ID"
                                            >
                                                <b-form-input
                                                    v-model="selectedEditableTag.id"
                                                    disabled
                                                    type="text"
                                                ></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="esef-row esef-row--controls">
                                        <b-button
                                            variant="primary"
                                            @click="onSaveEdit()"
                                        >Save</b-button>
                                        <b-button
                                            style="margin-left: auto;"
                                            variant="danger"
                                            @click="onRemoveEdit()"
                                        >Remove</b-button>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
        <div class="esef__error" v-else>
            You have to select the report on the <a href="/">Dashboard</a> page
        </div>
    </div>
</template>


<script>
import axios from 'axios'
const FileSaver = require('file-saver')

export default {
  name: 'Esef',
  components: {},
  data() {
    return {
        activeTab: 0,
        selectedTableName: null,
        selectedTag: null,
        statusSignMinus: false,
        reportID: null,
        file: null,
        fileIsLodaing: false,
        tableNames: [],
        tags: [],
        selectedEditableTag: null,
        tagsIdsInFrame: [],
        document: {
            context: '',
            htmlAttr: []
        },
        editFormats: [
            {
                "value": "ixt:num-dot-decimal",
                "text": "ixt:num-dot-decimal"
            },
            {
                "value": "ixt:num-comma-decimal",
                "text": "ixt:num-comma-decimal"
            },
            {
                "value": "ixt:fixed-zero",
                "text": "ixt:fixed-zero"
            }
        ],
        stateIframeScale: 1
    }
  },
  created () {
    const url = new URL(location.href)
    this.reportID = url.searchParams.get('id')

    this.getTableNames()
    this.getInfo()
  },
  mounted () {
    this.insertFiletoIFRAME('<html><body style="display: flex; align-items: center; justify-content: center; font-family: sans-serif; font-size: 30px; color: #d1cccc; font-weight: 100;">Upload a report</body></html>')
  },
  methods: {
        zoomIn () {
            this.stateIframeScale += 0.1
            this.$refs.fileFrame.style.transform = `scale(${this.stateIframeScale})`
        },
        zoomOut () {
            this.stateIframeScale -= 0.1
            this.$refs.fileFrame.style.transform = `scale(${this.stateIframeScale})`
        },
        zoomReset () {
            this.stateIframeScale = 1
            this.$refs.fileFrame.style.transform = `scale(${this.stateIframeScale})`
        },
        cloudGetReport () {
            this.fileIsLodaing = true
            axios.get(`https://esef.luca.report/api/ESEFXHTML/${this.reportID}`)
                .then(response => {
                    console.log(response)
                    this.insertFiletoIFRAME(response.data.content)
                    this.fileIsLodaing = false
                })
                .catch(error => {
                    console.log(error)
                    this.fileIsLodaing = false
                })
        },
        cloudUploadReport (method) {
            const completedDocument = this.$refs.fileFrame.contentDocument;
            const frameClone = document.createElement('iframe');
            frameClone.setAttribute('opacity', 0);
            document.body.appendChild(frameClone); 
            const frameDocument = frameClone.contentDocument;
            frameDocument.open();
            frameDocument.writeln(completedDocument.documentElement.outerHTML);
            frameDocument.close();
            Array.from(frameDocument.getElementsByTagName('mark')).forEach((el) => {
                el.parentNode.innerHTML = el.parentNode.innerHTML.replace(/(<([^>]+)>)/gm, '');
            });
            let raw = completedDocument.documentElement.outerHTML
            raw = raw.replace(/<mark[^>]*>|<\/mark>/gm, '');
            raw = raw.replace(/<(path|img|xml|meta)(.*?)\/>/gm, '<$1$2></$1>')
            raw = raw.replace(/<(path|img|xml|meta)(.*?)>/gm, '<$1$2></$1>')

            raw = '<?xml version="1.0" encoding="UTF-8"?>' + raw

            if (method === 'post') {
                axios.post(`https://esef.luca.report/api/ESEFXHTML/${this.reportID}`, {
                    content: raw
                })
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            } else if (method === 'patch') {
                axios.patch(`https://esef.luca.report/api/ESEFXHTML/${this.reportID}`, {
                    content: raw
                })
                    .then(response => {
                        console.log(response)
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }

            frameClone.remove()
        },
        cloudDownloadReport () {
            this.downloadCurrentState()
        },
        cloudRemoveReport () {
            axios.delete(`https://esef.luca.report/api/ESEFXHTML/${this.reportID}`)
                .then(response => {
                    console.log(response)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getInfo () {
            axios.get(`https://esef.luca.report/api/ESEF/getInfo?idReport=${this.reportID}`)
                .then(response => {
                    this.document.context = response.data.context
                    this.document.htmlAttr = response.data.htmlAttr

                    this.cloudGetReport()
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getTableNames () {
            axios.get(`https://esef.luca.report/api/ESEF/getTableNames?idReport=${this.reportID}`)
                .then(response => {
                    this.tableNames = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getTags (tableName) {
            axios.get(`https://esef.luca.report/api/ESEF/getTagsByTable?idReport=${this.reportID}&idTable=${tableName.value}`)
                .then(response => {
                    this.tags = response.data

                    // this.tags.push({
                    //     "text": "ifrs-full:NameOfReportingEntityOrOtherMeansOfIdentification",
                    //     "value": "ifrs-full:NameOfReportingEntityOrOtherMeansOfIdentification",
                    //     "indicativeValue": "Lukoil",
                    //     "context": "2020-01-01 | 2020-12-31",
                    //     "contextRef": "ID_549300LCJ1UJXHYBWI24_http_XXstandards.iso.orgXisoX17442_2020-01-01_2020-12-31",
                    //     "id": "ID_56c566d0b41559b1567c4a49c7646105",
                    //     "escape": "true",
                    //     "xml:lang": "en",
                    //     "type": "text"
                    // })

                    this.tags.forEach(tag => {
                        if (tag.id.length === 0) {
                            tag.id = "id_" + Math.random().toString(16).slice(2)
                        }
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        },
        onMark () {
            if (this.tagsIdsInFrame.includes(this.selectedTag.id)) {
                this.removeTagFromFrame(this.selectedTag.id)
            } else {
                let selection = this.getIframeSelectionText(this.$refs.fileFrame)
                if (selection.length === 0) {
                    alert('Please select text before mark')
                }
                this.wrapSelectedText()
                console.log(selection)
            }
        },
        onRemoveEdit() {
            this.removeTagFromFrame(this.selectedEditableTag.id)
            setTimeout(() => {
                this.activeTab = 0
            }, 0);
        },
        removeTagFromFrame (id) {
            const elMark = this.$refs.fileFrame.contentWindow.document.body.querySelector(`[id=${id}] mark`)
            const el = this.$refs.fileFrame.contentWindow.document.body.querySelector(`[id=${id}]`)

            elMark.replaceWith(...elMark.childNodes)
            el.replaceWith(...el.childNodes)

            const index = this.tagsIdsInFrame.indexOf(id);
            if (index > -1) {
                this.tagsIdsInFrame.splice(index, 1);
            }
        },
        wrapSelectedText() {
            const documentFrame = this.$refs.fileFrame.contentDocument
            const selection = documentFrame.getSelection()
            const self = this

            if (!selection.toString()) return;
            if (selection.anchorNode !== selection.focusNode) {
                alert('The highlighted content is in different DOM elements. Such content cannot be wrapped in tag. This action will break the document. Are you sure you want to continue?');
                return;
            }

            for(let i = 0; i < documentFrame.getSelection().rangeCount; i++) {
                let range = documentFrame.getSelection().getRangeAt(i);

                let nodeWrapper = documentFrame.createElement('mark');
                if (this.selectedTag.value.split(':')[0] === 'ifrs-full') {
                    nodeWrapper.setAttribute('style', 'background: #b7f1b7; cursor: pointer;')
                } else {
                    nodeWrapper.setAttribute('style', 'cursor: pointer;')
                }
                nodeWrapper.setAttribute('type', this.selectedTag.type)
                nodeWrapper.setAttribute('title', this.selectedTag.value)
                nodeWrapper.addEventListener('click', function () {
                    self.selectEditableTag(this.parentElement.id)
                })

                let nodeName = 'ix:nonfraction'

                if (this.selectedTag.type === 'text') {
                    nodeName = 'ix:nonnumeric'
                }

                let elNode = documentFrame.createElementNS('ix:urn', nodeName);

                if (this.selectedTag.hasOwnProperty('value')) {
                    elNode.setAttributeNS(null, 'name', this.selectedTag.value)
                }
                if (this.selectedTag.hasOwnProperty('contextRef')) {
                    elNode.setAttributeNS(null, 'contextref', this.selectedTag.contextRef)
                }
                if (this.selectedTag.hasOwnProperty('unit')) {
                    elNode.setAttributeNS(null, 'unitref', this.selectedTag.unit)
                }
                if (this.selectedTag.hasOwnProperty('decimals')) {
                    elNode.setAttributeNS(null, 'decimals', this.selectedTag.decimals)
                }
                if (this.selectedTag.hasOwnProperty('format')) {
                    elNode.setAttributeNS(null, 'format', this.selectedTag.format)
                }
                if (this.selectedTag.hasOwnProperty('scale')) {
                    elNode.setAttributeNS(null, 'scale', this.selectedTag.scale)
                }
                if (this.selectedTag.hasOwnProperty('escape')) {
                    elNode.setAttributeNS(null, 'escape', this.selectedTag.escape)
                }
                if (this.selectedTag.hasOwnProperty('xml:lang')) {
                    elNode.setAttribute('xml:lang', this.selectedTag['xml:lang'])
                }
                if (this.selectedTag.hasOwnProperty('id')) {
                    elNode.setAttributeNS(null, 'id', this.selectedTag.id)
                }

                if (this.selectedTag.signMinus) {
                    elNode.setAttributeNS(null, 'sign', '-')
                }

                nodeWrapper.appendChild(range.extractContents());
                elNode.appendChild(nodeWrapper);
                range.insertNode(elNode);
                this.tagsIdsInFrame.push(this.selectedTag.id)
            }
        },
        detectTags () {
            const iframeDocument = this.$refs.fileFrame.contentWindow.document;
            const self = this
            let els = iframeDocument.querySelectorAll('[contextref]')

            Array.prototype.forEach.call(els, function(el) {
                let style = ''
                let title = ''
                let type = el.nodeName.toLowerCase() === 'ix:nonfraction' ? 'monetary' : 'text'
                
                if (el.getAttribute('name').split(':')[0] === 'ifrs-full') {
                    style = 'background: #b7f1b7; cursor: pointer;'
                } else {
                    style = 'cursor: pointer;'
                }
                title = el.getAttribute('name')

                let org_html = el.innerHTML;
                let new_html = `<mark style="${style}" title="${title}" type="${type}">` + org_html + '</mark>';
                el.innerHTML = new_html;

                let nodeWrapper = el.querySelector('mark');
                nodeWrapper.addEventListener('click', () => self.selectEditableTag(el.getAttribute('id')));

                // el.insertBefore(nodeWrapper, el);
                // nodeWrapper.appendChild(el);

                self.tagsIdsInFrame.push(el.getAttribute('id'))
            });
        },
        selectEditableTag (id) {
            const el = this.$refs.fileFrame.contentWindow.document.body.querySelector(`[id=${id}]`)
            const obj = {}

            for (var att, i = 0, atts = el.attributes, n = atts.length; i < n; i++){
                att = atts[i];
                if (att.nodeName.indexOf('service') < 0) {
                    obj[att.nodeName] = att.nodeValue
                }
            }

            let markNode = el.querySelector('mark')
            obj.type = markNode.getAttribute('type')

            let html = el.innerHTML
            obj.value = html.replace(/<mark[^>]*>|<\/mark>/gm, '')

            this.statusSignMinus = obj.sign === '-'
            this.selectedEditableTag = obj
            setTimeout(() => {
                this.activeTab = 1
            }, 0);
        },
        onSaveEdit () {
            const elNode = this.$refs.fileFrame.contentWindow.document.body.querySelector(`[id=${this.selectedEditableTag.id}]`)
            if (this.selectedEditableTag.hasOwnProperty('name')) {
                elNode.setAttributeNS(null, 'name', this.selectedEditableTag.name)
            }
            if (this.selectedEditableTag.hasOwnProperty('contextref')) {
                elNode.setAttributeNS(null, 'contextref', this.selectedEditableTag.contextref)
            }
            if (this.selectedEditableTag.hasOwnProperty('unitref')) {
                elNode.setAttributeNS(null, 'unitref', this.selectedEditableTag.unitref)
            }
            if (this.selectedEditableTag.hasOwnProperty('decimals')) {
                elNode.setAttributeNS(null, 'decimals', this.selectedEditableTag.decimals)
            }
            if (this.selectedEditableTag.hasOwnProperty('format')) {
                elNode.setAttributeNS(null, 'format', this.selectedEditableTag.format)
            }
            if (this.selectedEditableTag.hasOwnProperty('scale')) {
                elNode.setAttributeNS(null, 'scale', this.selectedEditableTag.scale)
            }
            if (this.selectedEditableTag.hasOwnProperty('escape')) {
                elNode.setAttributeNS(null, 'escape', this.selectedEditableTag.escape)
            }
            if (this.selectedEditableTag.hasOwnProperty('xml:lang')) {
                elNode.setAttribute('xml:lang', this.selectedEditableTag['xml:lang'])
            }
            if (this.selectedEditableTag.hasOwnProperty('id')) {
                elNode.setAttributeNS(null, 'id', this.selectedEditableTag.id)
            }

            if (this.statusSignMinus) {
                elNode.setAttributeNS(null, 'sign', '-')
            } else {
                elNode.removeAttributeNS(null, 'sign')
            }
        },
        getIframeSelectionText(iframe) {
            const win = iframe.contentWindow;
            const doc = win.document;

            if (win.getSelection) {
                return win.getSelection().toString();
            } else if (doc.selection && doc.selection.createRange) {
                return doc.selection.createRange().text;
            }
        },
        onChangeTable () {
            this.selectedTag = null
            this.getTags(this.selectedTableName)
        },
        onChangeTag (tag) {
            this.selectedTag = tag
            this.onMark()
        },
        downloadCurrentState () {
            const completedDocument = this.$refs.fileFrame.contentDocument;
            const frameClone = document.createElement('iframe');
            frameClone.setAttribute('opacity', 0);
            document.body.appendChild(frameClone); 
            const frameDocument = frameClone.contentDocument;
            frameDocument.open();
            frameDocument.writeln(completedDocument.documentElement.outerHTML);
            frameDocument.close();
            Array.from(frameDocument.getElementsByTagName('mark')).forEach((el) => {
                el.parentNode.innerHTML = el.parentNode.innerHTML.replace(/(<([^>]+)>)/gm, '');
            });
            // const file = frameDocument.documentElement.outerHTML;
            // const file = completedDocument.documentElement.outerHTML;
            let raw = completedDocument.documentElement.outerHTML
            raw = raw.replace(/<mark[^>]*>|<\/mark>/gm, '');
            raw = raw.replace(/<(path|img|xml|meta)(.*?)\/>/gm, '<$1$2></$1>')
            raw = raw.replace(/<(path|img|xml|meta)(.*?)>/gm, '<$1$2></$1>')

            // eslint-disable-next-line no-debugger
            // debugger;

            const fileEncoding = completedDocument.characterSet;
            const prolog = `<?xml version="1.0" encoding="${ fileEncoding }"?>`;

            const blob = new Blob(
                [prolog + raw],
                {
                    // type: "application/xhtml+xml"
                    type: "text/html"
                }
            );

            const date = new Date
            let arFileName = this.file?.name.split('.') ?? ['report', 'xhtml']
            arFileName[0] += ' - '
            arFileName[0] += date.toISOString().slice(0,16)
            FileSaver.saveAs(blob, arFileName.join('.'));

            frameClone.remove()
        },
        onInputFile () {
            var file = this.file;

            if (file) {
                var reader = new FileReader();
                reader.readAsText(file, "UTF-8");

                reader.onloadstart = () => {
                    this.fileIsLodaing = true
                };

                reader.onloadend = () => {
                    this.fileIsLodaing = false
                };

                reader.onload = (evt) => {
                    this.insertFiletoIFRAME(evt.target.result)
                }

                reader.onerror = () => {
                    this.insertFiletoIFRAME('error loading')
                    this.fileIsLodaing = false
                }
            }
            
        },
        insertFiletoIFRAME (raw) {
            const iframeDocument = this.$refs.fileFrame.contentWindow.document;
            iframeDocument.open();
            iframeDocument.write(raw);
            iframeDocument.close();

            // eslint-disable-next-line no-debugger
            // debugger;

            // if (iframeDocument.documentElement.getAttribute('xmlns:ix') === null) {
            // }
            try {
                if (this.document.htmlAttr.length === 0) {
                    this.document.htmlAttr = [
                        {
                            key: 'xmlns',
                            value: 'http://www.w3.org/1999/xhtml'
                        },
                        {
                            key: 'xmlns:ix',
                            value: 'http://www.xbrl.org/2013/inlineXBRL'
                        },
                        {
                            key: 'xmlns:ixt',
                            value: 'http://www.xbrl.org/inlineXBRL/transformation/2020-02-12',
                        },
                        {
                            key: 'xmlns:link',
                            value: 'http://www.xbrl.org/2003/linkbase',
                        },
                        {
                            key: 'xmlns:xlink',
                            value: 'http://www.w3.org/1999/xlink',
                        },
                        {
                            key: 'xmlns:ifrs-full',
                            value: 'http://xbrl.ifrs.org/taxonomy/2020-03-16/ifrs-full',
                        },
                        {
                            key: 'xmlns:xbrli',
                            value: 'http://www.xbrl.org/2003/instance',
                        },
                        {
                            key: 'xmlns:iso4217',
                            value: 'http://www.xbrl.org/2003/iso4217',
                        },
                        {
                            key: 'xmlns:xbrldi',
                            value: 'http://xbrl.org/2006/xbrldi',
                        },
                        {
                            key: 'xmlns:esef_cor',
                            value: 'http://www.esma.europa.eu/taxonomy/2020-03-16/esef_cor',
                        },
                        {
                            key: 'xml:lang',
                            value: 'en'
                        }
                    ]
                }
                this.document.htmlAttr.forEach(attr => {
                    iframeDocument.documentElement.setAttribute(attr.key, attr.value)
                })

                if (iframeDocument.getElementsByTagName('ix:header').length > 0) {
                    iframeDocument.getElementsByTagName('ix:header')[0].parentNode.remove()
                }
                iframeDocument.querySelector('body').insertAdjacentHTML("afterbegin", this.document.context);
            } catch (error) {
                console.log(error)
            }

            this.tagsIdsInFrame = []

            this.detectTags()
        }
  }
}
</script>

<style lang="scss">
    .esef {
        .zoom {
            position: absolute;
            z-index: 2;
            bottom: 24px;
            top: auto;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            background-color: #fff;
        }

        .preloader {
            width: 66.666%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 62px;
            bottom: 0;
            height: auto;
        }
    }

    .esef__global-actions {
        position: relative;
    }

    #app .esef-add .list-group-item.active {
        background-color: rgb(8 66 152 / 15%) !important;
        color: inherit;
    }

    .esef-wrapper {
        padding: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .esef__global-actions {
        padding: 20px;
        background-color: #084298;

        button {
            margin-top: 15px;
            margin-left: 0;
        }
    }

    .esef__error {
        font-size: 24px;
    }

    .esef {
        margin: 0;
        display: flex;
        flex: 1;
        height: 100%;
        width: 100%;

        #edit-format label {
            flex: 0 0 100px;
            font-weight: 500;
        }

        #edit-format > div {
            flex: 1 1 100%;
            width: auto;
        }

        .custom-control-label:after, .custom-control-label:before {
            width: 1.5rem;
            height: 1.5rem;
        }

        .esef-value fieldset,
        .esef-value #edit-format {
            display: flex;
            width: 100%;
            align-items: baseline;
            min-height: 38px;
        }

        .esef-value {
            width: 100%;
        }

        legend {
            flex: 0 0 100px;
        }

        .esef-value fieldset > div {
            flex: 1 1 100%;
            width: auto;
        }

        .esef-edit textarea {
            min-height: 100px;
        }

        .esef__right {
            display: flex;
        }

        .esef__right .card {
            width: 100%;
            height: 100%;
            display: flex;
        }

        .tab-content {
            padding: 24px;
        }

        label {
            font-weight: 600;
        }

        .esef-row {
            display: flex;
        }

        .esef-label {
            margin-right: 8px;
            font-weight: 300;
            width: 160px;
            text-align: right;
            flex-shrink: 0;
        }

        .esef-value {
            color: #001a19;
            font-weight: 500;
            word-break: break-all;
        }

        .nav-link {
            color: #fff;
        }

        .card-header {
            background-color: rgb(32 100 101) !important;
            border-radius: 0 !important;
        }

        .card {
            border-radius: 0 !important;
        }
    }

    .esef__left {
        width: 66.666%;
        flex: 0 0 66.666%;
        position: relative;
        overflow: hidden;
    }

    .esef__right {
        width: 33.333%;
        flex: 0 0 33.333%;
    }

    .esef__left iframe {
        width: 100%;
        height: 100%;
        transform: scale(1);
        position: absolute;
        transform-origin: left top;
        transition: all 0.25s ease-in-out;
    }

    .esef__right {
        display: flex;
    }

    .esef__right .card {
        width: 100%;
        height: 100%;
        display: flex;
    }

    #app .esef .card-header {
        margin-bottom: 0;
        background: #084298 !important;
        padding: 0 30px;
        height: auto;
    }

    .esef .esef__right {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 16px !important;
    }
</style>
