import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import './styles/app.scss'
import './styles/drawflow.scss'

import Logo from './components/Logo.vue'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


Vue.component("ValidationObserver", ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('v-select', vSelect)
Vue.component('logo', Logo)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
