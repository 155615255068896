<template>
    <div class="login-page">
        <form class="form-signin">
            <h2>Luca</h2>
            <img class="mb-4" src="https://upload.wikimedia.org/wikipedia/commons/c/c8/Luca_Pacioli_in_the_Summa.jpg" alt="" width="100" height="100">
            <div id="google-signin-button"></div>
            <metamask @onComplete="onComplete"></metamask>
        </form>
    </div>
</template>

<script>
import Metamask from './Metamask.vue';

export default {
    components: {
        Metamask
    },
    data () {
        return {
            error: false,
            erorText: '',
            googleUser: null,
            metaMaskUser: null
        }
    },
    methods: {
        onComplete (data) {
            this.metaMaskUser = data
            console.log('data:', data);

            // eslint-disable-next-line no-undef
            const auth2 = gapi.auth2

            if (auth2) {
                auth2.getAuthInstance().signOut().then(() => {
                    console.log('Google User signed out.')
                    this.googleUser = null
                })
            }

            if (this.metaMaskUser.metaMaskAddress.length) {
                localStorage.setItem('metaMaskAddress', this.metaMaskUser.metaMaskAddress)
                this.$parent.profile = {
                    metaMaskAddress: this.metaMaskUser.metaMaskAddress
                }
                this.$parent.loginSuccess()
            }

        },
        onSignIn (googleUser) {
            this.metaMaskUser = null

            var profile = googleUser.getBasicProfile()
            this.googleUser = {
                name: profile.getName(),
                avatar: profile.getImageUrl(),
                email: profile.getEmail()
            }
            console.log(this.googleUser)
            this.$parent.profile = this.googleUser
            this.$parent.loginSuccess()
        }
    },
    mounted () {
        gapi.signin2.render('google-signin-button', {
            onsuccess: this.onSignIn,
            width: 240,
            height: 50,
            longtitle: true,
            theme: 'dark'
        })

        const storageMetaMaskAddress = localStorage.getItem('metaMaskAddress') 
        if (storageMetaMaskAddress !== null && storageMetaMaskAddress.length) {
            this.$parent.profile = {
                metaMaskAddress: storageMetaMaskAddress
            }
            this.$parent.loginSuccess()
        }
        
        gapi.load('auth2', () => {
            let authorized = false
            setTimeout(() => {
                if (gapi.auth2.getAuthInstance() !== null) {
                    authorized = gapi.auth2.getAuthInstance().isSignedIn.get()
                }
    
                if (!authorized) {
                    this.$parent.isLoading = false
                }
    
                console.log(authorized)
            }, 500);
        });
    }
}
</script>

<style lang="scss">
    #google-signin-button {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .login-page {
        width: 100%;
        max-width: 460px;
        text-align: center;
        margin: auto;
    }
</style>
