<template>
  <div id="app">
    <div v-if="isLoading" class="preloader">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <Login v-if="!authorized" />
    <div v-else class="layout-wrapper">
      <header class="header">
        <div class="header__logo">
          <logo />
        </div>
        <div class="header__nav">
          <nav class="header-nav">
            <a
              v-for="route in routes.filter(obj => obj.type === 'main')"
              :key="route.name"
              :href="route.path"
              :class="{
                'is-active': (route.path === '/' && currentRouteComponent === null) || (route.path === '/esef' && currentRouteComponent !== null)
              }"
              @click.prevent="changeRoute(route)"
            >
            {{ route.name }}
            <b-icon v-if="route.path === '/'" icon="table"></b-icon>
            <b-icon v-if="route.path === '/esef'" icon="grid1x2"></b-icon>
            </a>
          </nav>
          <!-- <b-dropdown v-if="selectedArea === 'objects'" class="lookup-list-dropdown" right text="Lookup List" variant="primary">
            <b-dropdown-item
                v-for="(item, j) in itemsAdminEnterInformation"
                :key="j"
                href="#"
                class="d-flex justify-content-between align-items-center"
                @click="getData(item.value, 'administration')"
                :active="item.value === currentKey"
              >
                {{ item.text }}
              </b-dropdown-item>
          </b-dropdown> -->
          <b-dropdown class="product-links-dropdown" right variant="primary">
            <template #button-content>
              <b-icon icon="link45deg"></b-icon> Product Links
            </template>
            <b-dropdown-item
                v-for="route in routes.filter(obj => obj.type === 'product')"
                :key="route.name"
                :href="route.path"
                @click.prevent="changeRoute(route)"
              >
                {{ route.name }}
              </b-dropdown-item>
          </b-dropdown>
          <div class="header__user" v-if="profile">
            <b-dropdown right>
              <template #button-content>
                <img v-if="profile.avatar" :src="profile.avatar" />
                <img v-else-if="profile.metaMaskAddress" src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" />
                <!-- <b-icon icon="link45deg"></b-icon> -->
              </template>
              <b-dropdown-item
                @click="handlerLogout()"
              ><b-icon icon="power" aria-hidden="true"></b-icon> Logout</b-dropdown-item>
            </b-dropdown>

            <!-- <span v-if="profile.metaMaskAddress">{{ profile.metaMaskAddress }}</span> -->
            <!-- <span v-else-if="profile.email">{{ profile.email }}</span> -->
          </div>
        </div>
      </header>
      <component class="page" v-if="currentRouteComponent" :is="currentRouteComponent"></component>

      <div
        class="layout"
        :class="{
          'layout--hide': !showAside
        }"
        v-else
      >
        <div class="layout__aside">
          <div class="select-area">
            <div
              v-for="area in listArea"
              :key="area.value"
              class="select-area__item"
              :class="{
                'select-area__item--active': selectedArea === area.value
              }"
            >
              <button class="select-area__btn" @click="changeArea(area.value)">
                <div class="select-area__icon"><b-icon :icon="area.icon" /></div>
                <span>{{area.text}}</span>
              </button>
            </div>
          </div>
          <b-button class="btn-collapse-nav" @click="showAside = !showAside">
            <b-icon v-if="showAside" icon="arrow-left" aria-hidden="true"></b-icon>
            <b-icon v-if="!showAside" icon="arrow-right" aria-hidden="true"></b-icon>
          </b-button>
          <aside class="aside">
            <b-form-group
              id="select-report-gr"
              label-for="select-report"
            >
              <div class="create-report-area">
                <b-button v-if="selectedReport && reports.length" variant="outline-secondary" @click="info(null, null, $event.target, 'delete-report')"><b-icon icon="trash" aria-hidden="true"></b-icon> Delete</b-button>
                <b-button variant="primary" @click="info(null, null, $event.target, 'new-report')"><b-icon icon="plus" aria-hidden="true"></b-icon> Create Report</b-button>
              </div>
              <v-select
                id="select-report"
                v-model="selectedReport"
                :options="reports"
                @input="changeReport()"
                label="text"
                :clearable="false"
                :searchable="true"
                placeholder="-- Select existing report --"
              ></v-select>
            </b-form-group>

            <b-card-group
              v-if="selectedArea === 'objects'"
              v-show="selectedReport && reports.length"
              deck
            >
              <b-card header="Enter Report Information:">
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, j) in itemsEnterInformation"
                    :key="j"
                    href="#"
                    class="d-flex justify-content-between align-items-center"
                    @click="getData(item.value)"
                    :active="item.value === currentKey"
                  >
                    {{ item.text }}
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-card-group>
            <div class="global-actions" v-if="selectedArea === 'objects'">
              <b-button v-if="selectedReport && reports.length" @click="getXMLs()" variant="primary">Generate report</b-button>
            </div>

            <b-card-group
              v-if="selectedArea === 'fragments'"
              v-show="selectedReport && reports.length"
              deck
            >
              <b-card>
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, j) in itemsEnterInformationFragments"
                    :key="j"
                    href="#"
                    class="d-flex justify-content-between align-items-center"
                    @click="getData(item.value, 'Rendering')"
                    :active="item.value === currentKey"
                  >
                    {{ item.text }}
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-card-group>

            <b-card-group
              v-if="selectedArea === 'administration'"
              deck
            >
              <b-card>
                <b-list-group>
                  <b-list-group-item
                    v-for="(item, j) in itemsAdminEnterInformation"
                    :key="j"
                    href="#"
                    class="d-flex justify-content-between align-items-center"
                    @click="getData(item.value, 'administration')"
                    :active="item.value === currentKey"
                  >
                    {{ item.text }}
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-card-group>
          </aside>
        </div>
        <div class="layout__main">
          <main class="main">
            <div
              class="main__container"
            >
              <div v-if="errorGetTable.length > 0" class="error" v-html="errorGetTable"></div>
              <div class="main__form-data" style="margin-bottom: 12px;" v-else-if="currentKey === 'General'">
                  <h1>General Form</h1>
                  <validation-observer ref="observer">
                    <b-form>
                      <ValidationProvider
                        v-for="obj in app.availableFields.filter(obj => obj.name.indexOf('app_') < 0)"
                        :key="obj.name"
                        :rules="getRegexValidationRule(obj)"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          v-show="obj.hasOwnProperty('hidden') ? !obj.hidden : true"
                          :label="obj.name"
                          :label-for="obj.name"
                          :class="{
                            'form-group--select' : obj.type === 'select'
                          }"
                        >
                          <template #label>
                            <span>
                              {{obj.name}}
                              <span v-if="obj.tooltip" v-b-tooltip.hover :title="obj.tooltip">
                                <b-icon icon="question-circle-fill" aria-hidden="true"></b-icon>
                              </span>
                            </span>
                          </template>
                          <b-input-group
                            v-if="obj.type === 'datepicker'"
                          >
                            <b-form-input
                              :id="obj.name"
                              type="text"
                              :placeholder="obj.placeholder"
                              v-model="infoModal.form[obj.name]"
                              :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                              :state="getValidationState(validationContext)"
                              autocomplete="off"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-form-datepicker
                                v-model="infoModal.form[obj.name]"
                                :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                                :state="getValidationState(validationContext)"
                                button-only
                                right
                                locale="en-US"
                                :aria-controls="obj.name"
                                @context="onContextDatepicker"
                              ></b-form-datepicker>
                            </b-input-group-append>
                          </b-input-group>
                          <b-form-textarea
                            v-if="obj.type === 'textarea'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="infoModal.form[obj.name]"
                            :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                            rows="3"
                            max-rows="6"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>
                          <b-form-input
                            v-if="obj.type === 'text'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="infoModal.form[obj.name]"
                            :state="getValidationState(validationContext)"
                            :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                            type="text"
                          ></b-form-input>
                          <v-select
                            v-if="obj.type === 'select' && obj.hasOwnProperty('taggable') && obj.hasOwnProperty('ajax') && obj.ajax"
                            :id="obj.name"
                            v-model="infoModal.form[obj.name]"
                            :options="obj.options"
                            :reduce="el => el.value"
                            @search="(search, loading) => { 
                              onInputSelectAjax(search, loading, obj)
                            }"
                            @input="checkRelatedFields(obj)"
                            label="text"
                            :class="[
                              `form-group--state-${getValidationState(validationContext, obj, true)}`
                            ]"
                            :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                            :clearable="false"
                            :searchable="true"
                            taggable
                            :createOption="val => ({ 
                              text: val,
                              value: val
                            })"
                          >
                            <template #search="{attributes, events}">
                              <input
                                class="vs__search"
                                :state="getValidationState(validationContext, obj, true)"
                                v-bind="attributes"
                                v-on="events"
                              />
                            </template>
                          </v-select>
                          <v-select
                            v-else-if="obj.type === 'select' && obj.hasOwnProperty('taggable')"
                            :id="obj.name"
                            v-model="infoModal.form[obj.name]"
                            :options="obj.options"
                            :reduce="el => el.value"
                            @input="checkRelatedFields(obj)"
                            label="text"
                            :class="[
                              `form-group--state-${getValidationState(validationContext, obj, true)}`
                            ]"
                            :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                            :clearable="false"
                            :searchable="true"
                            taggable
                            :createOption="val => ({ 
                              text: val,
                              value: val
                            })"
                          >
                            <template #search="{attributes, events}">
                              <input
                                class="vs__search"
                                :state="getValidationState(validationContext, obj, true)"
                                v-bind="attributes"
                                v-on="events"
                              />
                            </template>
                          </v-select>
                          <v-select
                            v-else-if="obj.type === 'select'"
                            :id="obj.name"
                            v-model="infoModal.form[obj.name]"
                            :options="obj.options"
                            :reduce="el => el.value"
                            @input="checkRelatedFields(obj)"
                            label="text"
                            :class="[
                              `form-group--state-${getValidationState(validationContext, obj, true)}`
                            ]"
                            :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                            :clearable="false"
                            :searchable="true"
                          >
                            <template #search="{attributes, events}">
                              <input
                                class="vs__search"
                                :state="getValidationState(validationContext, obj, true)"
                                v-bind="attributes"
                                v-on="events"
                              />
                            </template>
                          </v-select>
                          <span
                            class="invalid-feedback"
                            v-if="obj.type !== 'select' && Object.keys(getRegexValidationRule(obj)).length > 0 && getValidationState(validationContext) === false"
                          >{{ getErrorValidation(validationContext, obj) }}</span>
                          <span
                            class="invalid-feedback"
                            v-if="obj.type === 'select' && Object.keys(getRegexValidationRule(obj)).length > 0 && getValidationState(validationContext, obj, true) === false"
                          >{{ getErrorValidation(validationContext, obj) }}</span>
                        </b-form-group>
                      </ValidationProvider>
                      <div class="dimensions" v-if="app.dimensionsTemplate.length">
                        <b-button variant="outline-primary" @click="openDimensionsModal('add')">Edit dimensions</b-button>
                        <b-button variant="outline-primary" style="margin-left: 1rem;" @click="openParentheticalsModal('add')">Edit parentheticals</b-button>
                      </div>
                    </b-form>
                    <b-button
                      variant="success"
                      @click="onOkInfoModal()"
                    >Save</b-button>
                  </validation-observer>
                </div>
              <div v-else v-show="currentKey.length && errorGetTable.length === 0">
                <div style="margin-bottom: 12px;" v-if="errorGetTable.length === 0 && (selectedArea === 'objects' || selectedArea === 'administration')">
                  <b-button v-b-toggle.collapse-1 variant="outline-primary" style="margin-bottom: 8px;">Toggle Filter</b-button>
                  <b-collapse id="collapse-1">
                    <div class="filter" style="margin-bottom: 12px">
                      <b-form-group
                        label="Filter On"
                        description="Leave all unchecked to filter on all data"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-checkbox-group
                          v-model="filterOn"
                          :aria-describedby="ariaDescribedby"
                          class="mt-1"
                        >
                          <b-form-checkbox
                            v-for="item in filterNames"
                            :key="item.key"
                            :value="item.key"
                          >{{ item.label }}</b-form-checkbox>
                        </b-form-checkbox-group>
                      </b-form-group>

                      <b-form-group
                        label="Filter"
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-input-group size="sm">
                          <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                          ></b-form-input>

                          <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </b-collapse>
                </div>

                <b-tabs v-if="selectedArea === 'fragments'" class="tabs-fragments">
                    <template #tabs-end>
                      <b-nav-item
                        v-for="key in listTabsFragments"
                        :key="key.value"
                        href="#"
                        role="presentation"
                        @click="getData(currentKey, key.value)"
                        :active="selectedTabFragments === key.value ? true : false"
                      >{{ key.text }}</b-nav-item>
                    </template>
                </b-tabs>

                <!-- Main table element -->
                <div class="table-wrapper">
                  <div v-if="errorGetTable.length === 0">
                    <div class="global-actions" v-if="checkUserRights() && (selectedArea === 'objects' || selectedArea === 'administration')">
                      <b-button
                        variant="outline-primary"
                        title="Add data"
                        @click="info(null, null, $event.target, 'add')"
                      ><b-icon icon="plus" aria-hidden="true"></b-icon> Add</b-button>

                      <b-button
                        variant="outline-primary"
                        title="Upload data from Excel"
                        @click="info(null, null, $event.target, 'upload')"
                      ><b-icon style="margin-right: 0" icon="cloud-upload" aria-hidden="true"></b-icon></b-button>

                      <b-button
                        v-if="currentKey === 'Facts'"
                        variant="outline-primary"
                        title="Upload Dimensions from Excel"
                        @click="info(null, null, $event.target, 'upload-dimensions')"
                      ><b-icon style="margin-right: 0.5rem" icon="cloud-upload" aria-hidden="true"></b-icon> Dimensions</b-button>

                      <b-button
                        v-if="currentKey === 'Facts'"
                        variant="outline-primary"
                        title="Upload Parentheticals from Excel"
                        @click="info(null, null, $event.target, 'upload-parentheticals')"
                      ><b-icon style="margin-right: 0.5rem" icon="cloud-upload" aria-hidden="true"></b-icon> Parentheticals</b-button>

                      <b-button
                        variant="outline-primary"
                        title="Download XLSX"
                        @click="downloadTableXLSX()"
                      ><b-icon style="margin-right: 0" icon="cloud-download" aria-hidden="true"></b-icon></b-button>

                      <b-button
                        variant="outline-danger"
                        title="Clear data"
                        @click="dialogState = true"
                      ><b-icon style="margin-right: 0" icon="cloud-slash" aria-hidden="true"></b-icon></b-button>

                      <b-button
                        v-if="currentKey === 'Associations'"
                        variant="outline-primary"
                        title="Open Calculation Constructor"
                        @click="showCalculationConstructor()"
                      ><b-icon style="margin-right: 0.5rem" icon="diagram3" aria-hidden="true"></b-icon> Calculation Constructor</b-button>

                      <div
                        v-for="(modal, i) in app.modal"
                        :key="i"
                      >
                        <b-button
                          v-if="modal.type === 'link'"
                          variant="outline-primary"
                          :href="modal.data"
                          target="_blank"
                        ><span v-html="modal.buttonText"></span></b-button>
                        <b-button
                          v-if="modal.type === 'download'"
                          variant="outline-primary"
                          :href="modal.data"
                          download
                        ><span v-html="modal.buttonText"></span></b-button>
                        <b-button
                          v-if="modal.type === 'iframe' || modal.type === 'html'"
                          variant="outline-primary"
                          @click="showModal(modal)"
                        ><span v-html="modal.buttonText"></span></b-button>
                      </div>
                    </div>

                    <d3-tree
                      v-if="showTree"
                      :items="items"
                      :fields="fields"
                    ></d3-tree>

                    <vue-pivottable-ui
                      v-if="showPivot"
                      :data="items"
                      :rows="fields.map(obj => obj.key)"
                    >
                      <!-- :cols="['shape']" -->
                    </vue-pivottable-ui>

                    <div v-if="!showPivot" class="main-table-wrapper">
                      <b-table
                        id="main-table"
                        :items="items"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        :tbody-tr-class="rowClass"
                        stacked="md"
                        show-empty
                        small
                        @filtered="onFiltered"
                      >
                        <template #table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="{
                              width: field.key === 'expand' ? '30px' : '',
                              opacity: field.key === 'expand' ? '0' : '1'
                            }"
                          >
                        </template>

                        <template #cell(expand)="row">
                          <b-button
                            class="btn-action-dimensions"
                            :class="{
                              'visible': row.item.hasOwnProperty('items') && row.item.items.length > 0
                            }"
                            style="border: none !important; padding: 0 !important;"
                            variant="outline-primary"
                            size="sm"
                            @click="row.toggleDetails"
                          >
                            <b-icon v-if="!row.detailsShowing" icon="caret-down" aria-hidden="true"></b-icon>
                            <b-icon v-if="row.detailsShowing" icon="caret-up" aria-hidden="true"></b-icon>
                          </b-button>
                        </template>
                        <template #cell(actions)="row">
                          <b-button
                            v-if="currentKey === 'Facts'"
                            class="btn-action-dimensions mr-1"
                            :class="{
                              'visible': row.item.Dimensions || row.item.Parentheticals
                            }"
                            variant="outline-primary"
                            size="sm"
                            @click="row.toggleDetails"
                          >
                            <b-icon v-if="!row.detailsShowing" icon="caret-down-square" aria-hidden="true"></b-icon>
                            <b-icon v-if="row.detailsShowing" icon="caret-up-square" aria-hidden="true"></b-icon>
                          </b-button>
                          <b-button v-if="checkUserRights()" variant="outline-primary" size="sm" @click="info(row.item, row.index, $event.target, 'edit')" class="mr-1">
                            <b-icon icon="pencil" aria-hidden="true"></b-icon>
                          </b-button>
                          <b-button v-if="checkUserRights()" variant="outline-danger" size="sm" @click="info(row.item, row.index, $event.target, 'delete')" class="mr-1">
                            <b-icon icon="trash" aria-hidden="true"></b-icon>
                          </b-button>
                        </template>
                        <template #cell()="row">
                          <div v-html="row.value"></div>
                        </template>
                        <template #row-details="row">
                          <table class="row__dimensions" v-if="row.item.Dimensions">
                            <thead>
                              <tr>
                                <th
                                  v-for="(label, i) in Object.keys(buildDimensionArr(row.item.Dimensions)[0])"
                                  :key="i"
                                >
                                  {{ label }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, j) in buildDimensionArr(row.item.Dimensions)"
                                :key="j"
                              >
                                <td
                                  v-for="(label, k) in Object.keys(item)"
                                  :key="k"
                                >
                                  {{ item[label] }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table class="row__dimensions" v-if="row.item.Parentheticals">
                            <thead>
                              <tr>
                                <th
                                  v-for="(label, i) in Object.keys(buildParentheticalArr(row.item.Parentheticals)[0])"
                                  :key="i"
                                >
                                  {{ label }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(item, j) in buildParentheticalArr(row.item.Parentheticals)"
                                :key="j"
                              >
                                <td
                                  v-for="(label, k) in Object.keys(item)"
                                  :key="k"
                                >
                                  {{ item[label] }}
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <div v-if="row.item.hasOwnProperty('items') && row.item.items.length > 0">
                            <table-tree
                                v-for="(child, i) in row.item.items"
                                :key="i"
                                :node="child"
                                :fields="fields"
                              >
                            </table-tree>
                          </div>
                        </template>
                      </b-table>
                    </div>

                    <!-- pagination -->
                    <div class="pagination" v-if="selectedArea === 'objects' || selectedArea === 'administration'">
                      <b-form-group
                        label="Per page"
                        label-for="per-page-select"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                      >
                        <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>

                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="fill"
                        size="sm"
                        class="my-0"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Info modal -->
              <b-modal :id="infoModal.id" :title="infoModal.title" @hide="resetInfoModal">
                <b-alert :show="showAlertAction" variant="danger" v-html="errorTextAction"></b-alert>
                <div v-if="infoModal.action === 'delete'">
                  <pre>{{ infoModal.content }}</pre>
                </div>
                <div v-if="infoModal.action === 'delete-report'"></div>
                <div v-if="infoModal.action === 'new-report'">
                  <b-form>
                    <b-form-group
                      id="newReportName"
                      label="Report name"
                    >
                      <b-form-input
                        v-model="infoModal.form['newReportName']"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-form>
                </div>
                <div v-if="infoModal.action === 'upload' || infoModal.action === 'upload-dimensions' || infoModal.action === 'upload-parentheticals'">
                  <input type="file" class="form-control" id="file" :accept="['xlsx', 'xls']" @change="changeFile" />
                  <div class="info-modal__json" v-if="infoModal.content">
                    <pre>{{ infoModal.content }}</pre>
                  </div>
                </div>
                <b-form v-if="infoModal.action === 'edit'">
                  <b-form-group
                    v-for="obj in app.availableFields.filter(obj => obj.name.indexOf('app_') < 0)"
                    :key="obj.name"
                    :label="obj.name"
                    :label-for="obj.name"
                    :class="{
                      'form-group--select' : obj.type === 'select'
                    }"
                  >
                    <b-form-textarea
                      v-if="obj.type === 'textarea'"
                      :id="obj.name"
                      :placeholder="obj.placeholder"
                      v-model="infoModal.form[obj.name]"
                      rows="3"
                      max-rows="6"
                      :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                    ></b-form-textarea>
                    <b-form-input
                      v-if="obj.type === 'text'"
                      :id="obj.name"
                      :placeholder="obj.placeholder"
                      v-model="infoModal.form[obj.name]"
                      type="text"
                      :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                    ></b-form-input>
                    <v-select
                      v-if="obj.type === 'select' && obj.hasOwnProperty('taggable') && obj.hasOwnProperty('ajax') && obj.ajax"
                      :id="obj.name"
                      v-model="infoModal.form[obj.name]"
                      :options="obj.options"
                      :reduce="el => el.value"
                      @search="(search, loading) => { 
                        onInputSelectAjax(search, loading, obj)
                      }"
                      @input="checkRelatedFields(obj)"
                      label="text"
                      :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                      :clearable="false"
                      :searchable="true"
                      taggable
                      :createOption="val => ({ 
                        text: val,
                        value: val
                      })"
                    ></v-select>
                    <v-select
                      v-else-if="obj.type === 'select' && obj.hasOwnProperty('taggable')"
                      :id="obj.name"
                      v-model="infoModal.form[obj.name]"
                      :options="obj.options"
                      :reduce="el => el.value"
                      @input="checkRelatedFields(obj)"
                      label="text"
                      :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                      :clearable="false"
                      :searchable="true"
                      taggable
                      :createOption="val => ({ 
                        text: val,
                        value: val
                      })"
                    ></v-select>
                    <v-select
                      v-else-if="obj.type === 'select'"
                      :id="obj.name"
                      v-model="infoModal.form[obj.name]"
                      :options="obj.options"
                      :reduce="el => el.value"
                      @input="checkRelatedFields(obj)"
                      label="text"
                      :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                      :clearable="false"
                      :searchable="true"
                    ></v-select>
                  </b-form-group>
                  <div class="dimensions" v-if="app.dimensionsTemplate.length">
                    <b-button variant="outline-primary" @click="openDimensionsModal('edit')">Edit dimensions</b-button>
                    <b-button variant="outline-primary" style="margin-left: 1rem;" @click="openParentheticalsModal('add')">Edit parentheticals</b-button>
                  </div>
                </b-form>
                <validation-observer ref="observer">
                  <b-form v-if="infoModal.action === 'add'">
                    <ValidationProvider
                      v-for="obj in app.availableFields.filter(obj => obj.name.indexOf('app_') < 0)"
                      :key="obj.name"
                      :rules="getRegexValidationRule(obj)"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        :label="obj.name"
                        :label-for="obj.name"
                        :class="{
                          'form-group--select' : obj.type === 'select'
                        }"
                      >
                        <template #label>
                          <span>
                            {{obj.name}}
                            <span v-if="obj.tooltip" v-b-tooltip.hover :title="obj.tooltip">
                              <b-icon icon="question-circle-fill" aria-hidden="true"></b-icon>
                            </span>
                          </span>
                        </template>
                        <b-form-textarea
                          v-if="obj.type === 'textarea'"
                          :id="obj.name"
                          :placeholder="obj.placeholder"
                          v-model="infoModal.form[obj.name]"
                          :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                          rows="3"
                          max-rows="6"
                          :state="getValidationState(validationContext)"
                        ></b-form-textarea>
                        <b-form-input
                          v-if="obj.type === 'text'"
                          :id="obj.name"
                          :placeholder="obj.placeholder"
                          v-model="infoModal.form[obj.name]"
                          :state="getValidationState(validationContext)"
                          :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                          type="text"
                        ></b-form-input>
                        <v-select
                          v-if="obj.type === 'select' && obj.hasOwnProperty('taggable') && obj.hasOwnProperty('ajax') && obj.ajax"
                          :id="obj.name"
                          v-model="infoModal.form[obj.name]"
                          :options="obj.options"
                          :reduce="el => el.value"
                          @search="(search, loading) => { 
                            onInputSelectAjax(search, loading, obj)
                          }"
                          @input="checkRelatedFields(obj)"
                          label="text"
                          :class="[
                            `form-group--state-${getValidationState(validationContext, obj, true)}`
                          ]"
                          :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                          :clearable="false"
                          :searchable="true"
                          taggable
                          :createOption="val => ({ 
                            text: val,
                            value: val
                          })"
                        >
                          <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :state="getValidationState(validationContext, obj, true)"
                              v-bind="attributes"
                              v-on="events"
                            />
                          </template>
                        </v-select>
                        <v-select
                          v-else-if="obj.type === 'select' && obj.hasOwnProperty('taggable')"
                          :id="obj.name"
                          v-model="infoModal.form[obj.name]"
                          :options="obj.options"
                          :reduce="el => el.value"
                          @input="checkRelatedFields(obj)"
                          label="text"
                          :class="[
                            `form-group--state-${getValidationState(validationContext, obj, true)}`
                          ]"
                          :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                          :clearable="false"
                          :searchable="true"
                          taggable
                          :createOption="val => ({ 
                            text: val,
                            value: val
                          })"
                        >
                          <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :state="getValidationState(validationContext, obj, true)"
                              v-bind="attributes"
                              v-on="events"
                            />
                          </template>
                        </v-select>
                        <v-select
                          v-else-if="obj.type === 'select'"
                          :id="obj.name"
                          v-model="infoModal.form[obj.name]"
                          :options="obj.options"
                          :reduce="el => el.value"
                          @input="checkRelatedFields(obj)"
                          label="text"
                          :class="[
                            `form-group--state-${getValidationState(validationContext, obj, true)}`
                          ]"
                          :disabled="obj.hasOwnProperty('editable') ? !obj.editable : false"
                          :clearable="false"
                          :searchable="true"
                        >
                          <template #search="{attributes, events}">
                            <input
                              class="vs__search"
                              :state="getValidationState(validationContext, obj, true)"
                              v-bind="attributes"
                              v-on="events"
                            />
                          </template>
                        </v-select>
                        <span
                          class="invalid-feedback"
                          v-if="obj.type !== 'select' && Object.keys(getRegexValidationRule(obj)).length > 0 && getValidationState(validationContext) === false"
                        >{{ getErrorValidation(validationContext, obj) }}</span>
                        <span
                          class="invalid-feedback"
                          v-if="obj.type === 'select' && Object.keys(getRegexValidationRule(obj)).length > 0 && getValidationState(validationContext, obj, true) === false"
                        >{{ getErrorValidation(validationContext, obj) }}</span>
                      </b-form-group>
                    </ValidationProvider>
                    <div class="dimensions" v-if="app.dimensionsTemplate.length">
                      <b-button variant="outline-primary" @click="openDimensionsModal('add')">Edit dimensions</b-button>
                      <b-button variant="outline-primary" style="margin-left: 1rem;" @click="openParentheticalsModal('add')">Edit parentheticals</b-button>
                    </div>
                  </b-form>
                </validation-observer>
                <template #modal-footer="{ hide }">
                  <b-button
                    v-if="infoModal.action !== 'delete' && infoModal.action !== 'delete-report' && infoModal.action !== 'upload' && infoModal.action !== 'upload-dimensions' && infoModal.action !== 'upload-parentheticals'" size="sm"
                    variant="success"
                    @click="onOkInfoModal()"
                  >Save</b-button>
                  <b-button
                    v-if="infoModal.action === 'delete' || infoModal.action === 'delete-report'" size="sm" variant="success" @click="onOkInfoModal()">Delete</b-button>
                  <b-button
                    v-if="infoModal.action === 'upload' || infoModal.action === 'upload-dimensions' || infoModal.action === 'upload-parentheticals'" size="sm" variant="success" @click="onOkInfoModal()">Send data</b-button>
                  <b-button size="sm" variant="danger" @click="hide()">Cancel</b-button>
                </template>
              </b-modal>

              <!-- Dimensions modal -->
              <b-modal :id="dimensionsModal.id" :title="dimensionsModal.title">
                <b-alert :show="showAlertAction" variant="danger" v-html="errorTextAction"></b-alert>
                <validation-observer ref="observer">
                  <b-form>
                    <div class="dimensions dimensions--modal" v-if="app.dimensionsTemplate.length && dimensionsModal.action === 'add'">
                      <div
                        v-for="(arr, i) in dimensionsModal.dimensions"
                        :key="`dimensionsGroup-${i+1}`"
                      >
                        <b-form-group
                          v-for="(obj, j) in arr"
                          :key="`dimensions-el-${obj.name}-${j}`"
                          :id="obj.name"
                          :label="obj.name.split('-')[0]"
                          :label-for="obj.name"
                        >
                          <b-form-textarea
                            v-if="obj.type === 'textarea'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="dimensionsModal.form[obj.name]"
                            rows="3"
                            max-rows="6"
                          ></b-form-textarea>
                          <b-form-input
                            v-if="obj.type === 'text'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="dimensionsModal.form[obj.name]"
                            type="text"
                          ></b-form-input>
                          <v-select
                            v-if="obj.type === 'select'"
                            :id="obj.name"
                            :options="obj.options"
                            v-model="dimensionsModal.form[obj.name]"
                            :reduce="el => el.value"
                            label="text"
                            :clearable="false"
                            :searchable="true"
                          ></v-select>
                        </b-form-group>
                        <b-button variant="outline-danger" @click="removeDimension(arr[0].name.split('-')[1])"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                      </div>
                      <b-button variant="outline-primary" @click="addDimension()">+ Add dimension</b-button>
                    </div>
                    <div class="dimensions dimensions--modal" v-if="app.dimensionsTemplate.length && dimensionsModal.action === 'edit'">
                      <div
                        v-for="(arr, i) in dimensionsModal.dimensions"
                        :key="`dimensionsGroup-${i+1}`"
                      >
                        <b-form-group
                          v-for="(obj, j) in arr"
                          :key="`dimensions-el-${obj.name}-${j}`"
                          :id="obj.name"
                          :label="obj.name.split('-')[0]"
                          :label-for="obj.name"
                        >
                          <b-form-textarea
                            v-if="obj.type === 'textarea'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="dimensionsModal.form[obj.name]"
                            rows="3"
                            max-rows="6"
                          ></b-form-textarea>
                          <b-form-input
                            v-if="obj.type === 'text'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="dimensionsModal.form[obj.name]"
                            type="text"
                          ></b-form-input>
                          <v-select
                            v-if="obj.type === 'select'"
                            :id="obj.name"
                            :options="obj.options"
                            v-model="dimensionsModal.form[obj.name]"
                            :reduce="el => el.value"
                            label="text"
                            :clearable="false"
                            :searchable="true"
                          ></v-select>
                        </b-form-group>
                        <b-button variant="outline-danger" @click="removeDimension(arr[0].name.split('-')[1])"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                      </div>
                      <b-button variant="outline-primary" @click="addDimension()">+ Add dimension</b-button>
                    </div>
                  </b-form>
                </validation-observer>
                <template #modal-footer="{ hide }">
                  <b-button
                    size="sm"
                    variant="success"
                    @click="hide()"
                  >Save</b-button>
                </template>
              </b-modal>

              <!-- Dimensions modal -->
              <b-modal :id="parentheticalsModal.id" :title="parentheticalsModal.title">
                <b-alert :show="showAlertAction" variant="danger" v-html="errorTextAction"></b-alert>
                <validation-observer ref="observer">
                  <b-form>
                    <div class="dimensions dimensions--modal" v-if="app.parentheticalsTemplate.length && parentheticalsModal.action === 'add'">
                      <div
                        v-for="(arr, i) in parentheticalsModal.parentheticals"
                        :key="`parentheticalsGroup-${i+1}`"
                      >
                        <b-form-group
                          v-for="(obj, j) in arr"
                          :key="`parentheticals-el-${obj.name}-${j}`"
                          :id="obj.name"
                          :label="obj.name.split('-')[0]"
                          :label-for="obj.name"
                        >
                          <b-form-textarea
                            v-if="obj.type === 'textarea'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="parentheticalsModal.form[obj.name]"
                            rows="3"
                            max-rows="6"
                          ></b-form-textarea>
                          <b-form-input
                            v-if="obj.type === 'text'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="parentheticalsModal.form[obj.name]"
                            type="text"
                          ></b-form-input>
                          <v-select
                            v-if="obj.type === 'select'"
                            :id="obj.name"
                            :options="obj.options"
                            v-model="parentheticalsModal.form[obj.name]"
                            :reduce="el => el.value"
                            label="text"
                            :clearable="false"
                            :searchable="true"
                          ></v-select>
                        </b-form-group>
                        <b-button variant="outline-danger" @click="removeParenthetical(arr[0].name.split('-')[1])"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                      </div>
                      <b-button variant="outline-primary" @click="addParenthetical()">+ Add parenthetical</b-button>
                    </div>
                    <div class="dimensions dimensions--modal" v-if="app.parentheticalsTemplate.length && parentheticalsModal.action === 'edit'">
                      <div
                        v-for="(arr, i) in parentheticalsModal.parentheticals"
                        :key="`parentheticalsGroup-${i+1}`"
                      >
                        <b-form-group
                          v-for="(obj, j) in arr"
                          :key="`parentheticals-el-${obj.name}-${j}`"
                          :id="obj.name"
                          :label="obj.name.split('-')[0]"
                          :label-for="obj.name"
                        >
                          <b-form-textarea
                            v-if="obj.type === 'textarea'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="parentheticalsModal.form[obj.name]"
                            rows="3"
                            max-rows="6"
                          ></b-form-textarea>
                          <b-form-input
                            v-if="obj.type === 'text'"
                            :id="obj.name"
                            :placeholder="obj.placeholder"
                            v-model="parentheticalsModal.form[obj.name]"
                            type="text"
                          ></b-form-input>
                          <v-select
                            v-if="obj.type === 'select'"
                            :id="obj.name"
                            :options="obj.options"
                            v-model="parentheticalsModal.form[obj.name]"
                            :reduce="el => el.value"
                            label="text"
                            :clearable="false"
                            :searchable="true"
                          ></v-select>
                        </b-form-group>
                        <b-button variant="outline-danger" @click="removeParenthetical(arr[0].name.split('-')[1])"><b-icon icon="trash" aria-hidden="true"></b-icon></b-button>
                      </div>
                      <b-button variant="outline-primary" @click="addParenthetical()">+ Add parenthetical</b-button>
                    </div>
                  </b-form>
                </validation-observer>
                <template #modal-footer="{ hide }">
                  <b-button
                    size="sm"
                    variant="success"
                    @click="hide()"
                  >Save</b-button>
                </template>
              </b-modal>

              <!-- HTML/IFRAME modal -->
              <b-modal
                :id="modal.id"
                v-model="modal.show"
                :title="modal.title"
                @hide="resetModal"
                :modal-class="modal.title.length === 0 ? 'html-modal html-modal--title-disabled' : 'html-modal'"
              >
                <b-alert :show="showAlertAction" variant="danger" v-html="errorTextAction"></b-alert>
                <div class="html-modal__content">
                  <div class="html-modal__content-offsets" v-if="modal.type === 'html'" v-html="modal.data"></div>
                  <div v-if="modal.type === 'iframe'">
                    <iframe :src="modal.data" />
                  </div>
                  <div class="html-modal__flow" v-if="modal.type === 'flow'">
                    <div class="flow">
                      <div class="flow__nav">
                        <v-select
                          id="select-flow-table"
                          v-model="selectedFlowTable"
                          :options="listStructures"
                          @input="changeFlowTable()"
                          label="text"
                          :clearable="false"
                          :searchable="true"
                          placeholder="-- Select table --"
                          :reduce="el => el.value"
                        >
                        </v-select>

                        <b-button
                          v-if="selectedFlowTable"
                          class="flow__save"
                          variant="primary"
                          @click="saveFlow()"
                        >Save</b-button>

                        <div class="flow__blocks">
                          <div
                            v-for="(block, i) in listFlowBlocks"
                            :key="i"
                            class="drag-drawflow"
                            draggable="true"
                            @dragstart="drag($event)"
                            :data-node="JSON.stringify(block)"
                          >
                            <span>{{block.name}}</span>
                          </div>
                        </div>
                      </div>
                      <div id="drawflow" @drop="drop($event)" @dragover="allowDrop($event)"></div>
                      <div class="zoom">
                        <b-icon @click="editor.zoom_in()" icon="zoom-in" aria-hidden="true"></b-icon>
                        <b-icon @click="editor.zoom_out()" icon="zoom-out" aria-hidden="true"></b-icon>
                        <b-icon @click="editor.zoom_reset()" icon="x-circle" aria-hidden="true"></b-icon>
                    </div>
                    </div>
                  </div>
                  <div class="html-modal__keys" v-if="modal.keys">
                    <b-button
                      :variant="activeVariantModalkey === key.value ? 'primary' : 'outline-primary'"
                      @click="chagneActiveVariantModalKey(key.value)"
                      v-for="key in variantsModal"
                      :key="key.value"
                    >{{ key.text }}</b-button>

                    <div class="html-modal__keys-radio" style="opacity: 0; overflow: hidden; width: 1px; height: 1px;">
                      <b-form-group label="" v-slot="{ ariaDescribedby }">
                        <b-form-radio v-model="selectedSegmentOrScenario" :aria-describedby="ariaDescribedby" name="SegmentOrScenario" value="segment">Segment</b-form-radio>
                        <b-form-radio v-model="selectedSegmentOrScenario" :aria-describedby="ariaDescribedby" name="SegmentOrScenario" value="scenario">Scenario</b-form-radio>
                      </b-form-group>
                    </div>

                    <b-button
                      variant="primary"
                      v-if="activeVariantModalkey === 'code'"
                      style="margin-top: auto;"
                      @click="downloadAllXMLsInZIP()"
                    ><b-icon style="margin-right: 0" icon="cloud-download" aria-hidden="true"></b-icon> Download All</b-button>
                    <b-button
                      variant="primary"
                      :style="activeVariantModalkey === 'code' ? 'margin-top: 10px;' : 'margin-top: auto;'"
                      target="_blank"
                      :href="`https://esef.luca.report/api/${this.getCurrentReportWithType()}/GetESEFZip`"
                    ><b-icon style="margin-right: 0" icon="cloud-download" aria-hidden="true"></b-icon> ESEF zip</b-button>
                  </div>
                  <div class="html-modal__variant" v-if="modal.type === 'code'">
                    <div class="html-modal__code" v-if="activeVariantModalkey === 'code'">
                      <div class="html-modal__alerts" v-if="errorsArrGenerate !== null">
                        <b-alert
                          :show="errorsArrGenerate !== null"
                          variant="danger"
                          v-for="(err, i) in errorsArrGenerate"
                          :key="i"
                        >{{ err }}</b-alert>
                      </div>
                      <div class="code" v-html="modal.code"></div>
                    </div>
                    <div class="html-modal__code" v-if="activeVariantModalkey === 'validation'">
                      <div class="html-modal__alerts">
                        <div
                          class="alert-accordion"
                          :class="{
                            'alert-accordion--opened': err.show
                          }"
                          v-for="(err, i) in validationJSON"
                          :key="i"
                        >
                          <div @click="showDetailAlert(err)">
                            <b-alert
                              :show="true"
                              :variant="err.level === 'error' ? 'danger' : err.level"
                            >
                              <div>{{ err.message.text }}</div>
                              <b-icon style="margin-right: 0" icon="arrow-down-circle" aria-hidden="true"></b-icon>
                            </b-alert>
                          </div>
                          <pre>
                            <code
                              :style="err.show ? 'display: block' : 'display: none'"
                              class="alert-accordion__body"
                              v-html="buildErrJson(err)"
                            >
                            </code>
                          </pre>
                        </div>
                      </div>
                      <!-- <div class="code"></div> -->
                    </div>
                  </div>
                </div>
                <template #modal-header="{}">
                  <h5 id="html-modal___BV_modal_title_" class="modal-title"></h5>
                  <div class="code-nav" v-if="activeVariantModalkey === 'code'">
                    <b-tabs>
                      <template #tabs-end>
                        <b-nav-item
                          v-for="key in modal.keys"
                          :key="key.value"
                          href="#"
                          role="presentation"
                          @click="generateXML(key.value)"
                          :active="activeXMLkey === key.value ? true : false"
                        >{{ key.text }}</b-nav-item>
                      </template>
                    </b-tabs>
                  </div>
                  <div class="code-nav" v-if="activeVariantModalkey === 'validation'">
                    <b-tabs>
                      <template #tabs-end>
                        <b-nav-item
                          v-for="key in validationKeys"
                          :key="key.value"
                          href="#"
                          role="presentation"
                          @click="getContentVaildationByKey(key.value)"
                          :active="activeValidationKey === key.value ? true : false"
                        >{{ key.text }}</b-nav-item>
                      </template>
                    </b-tabs>
                  </div>
                  <button @click="closeModal()" type="button" aria-label="Close" class="close">×</button>
                </template>
                <template #modal-footer="{}">
                  <b-button
                    v-if="modal.data && modal.type === 'code' && activeVariantModalkey === 'code'"
                    variant="primary"
                    :disabled="errorsArrGenerate !== null"
                    @click="downloadFileXML()"
                  ><b-icon style="margin-right: 0" icon="cloud-download" aria-hidden="true"></b-icon> Download file</b-button>
                  <b-button
                    v-if="modal.data && modal.type === 'code' && activeVariantModalkey === 'validation'"
                    variant="primary"
                    :disabled="validationJSON.length === 0"
                    @click="downloadFileValidation()"
                  ><b-icon style="margin-right: 0" icon="cloud-download" aria-hidden="true"></b-icon> Download file</b-button>
                </template>
              </b-modal>

              <b-overlay :show="dialogState" no-wrap @shown="onShownDialog()">
                <template #overlay>
                  <div
                    ref="dialog"
                    tabindex="-1"
                    role="dialog"
                    aria-modal="false"
                    aria-labelledby="form-confirm-label"
                    class="text-center p-3"
                  >
                    <p><strong id="form-confirm-label">Are you sure you want to clear all data in {{ getCuurentTableName() }}?</strong></p>
                    <div class="d-flex">
                      <b-button variant="outline-success" class="mr-3" @click="onOKDialog()">Delete</b-button>
                      <b-button variant="outline-danger" @click="onCancelDialog()">Cancel</b-button>
                    </div>
                  </div>
                </template>
              </b-overlay>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Login from './components/Login.vue'
import XLSX from 'xlsx'
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import { extend } from 'vee-validate';
import { regex } from 'vee-validate/dist/rules';
extend('regex', regex)
import { VuePivottableUi } from 'vue-pivottable'
import 'vue-pivottable/dist/vue-pivottable.css'

import Vue from 'vue'
import Drawflow from 'drawflow'
import 'drawflow/dist/drawflow.min.css'
window.Drawflow = Drawflow

const JSZip = require('jszip')
const FileSaver = require('file-saver')

import AboutPage from './components/AboutPage.vue'
import EsefPage from './components/EsefPage.vue'
import TableTree from "./components/TableTree.vue";
import D3Tree from "./components/D3Tree.vue";

export default {
  name: 'App',
  components: {
    Login,
    AboutPage,
    EsefPage,
    TableTree,
    D3Tree,
    VuePivottableUi
  },
  data() {
    return {
      editor: null,
      mobile_item_selec: '',
      mobile_last_move: null,
      showTree: false,
      showPivot: false,
      listStructures: [],
      selectedFlowTable: '',
      listFlowBlocks: [],
      selectedArea: 'objects',
      listArea: [
        {
          value: 'objects',
          text: 'Objects',
          icon: 'list-task'
        },
        {
          value: 'fragments',
          text: 'Fragments',
          icon: 'puzzle'
        },
        {
          value: 'administration',
          text: 'Lookup List',
          icon: 'card-list'
        }
      ],
      selectedTabFragments: '',
      listTabsFragments: [
        {
          value: 'Rendering',
          text: 'Rendering'
        },
        {
          value: 'ModelStructure',
          text: 'Model Structure'
        },
        {
          value: 'FactTable',
          text: 'Fact Table'
        },
        {
          value: 'BusinessRulesStructure',
          text: 'Business Rules Structure'
        },
        {
          value: 'BusinessRulesValidationResults',
          text: 'Business Rules Validation Results'
        },
        {
          value: 'Elements',
          text: 'Elements'
        }
      ],
      showAside: true,
      validationJSON: [],
      validationKeys: [
        {
          value: 'general',
          text: 'General'
        },
        {
          value: 'consistency',
          text: 'Consistency'
        },
        {
          value: 'arelle',
          text: 'Arelle'
        }
      ],
      activeValidationKey: '',
      variantsModal: [
        {
          value: 'code',
          text: 'Code'
        },
        {
          value: 'validation',
          text: 'Validation'
        }
      ],
      activeVariantModalkey: 'code',
      dialogState: false,
      routes: [
        {
          path: '/',
          name: 'Dashboard',
          type: 'main'
        },
        {
          path: '/esef',
          name: 'Tagger',
          type: 'main',
          component: EsefPage
        },
        {
          path: '/about',
          name: 'About',
          type: 'product',
          component: AboutPage
        },
        {
          path: 'http://accounting.auditchain.finance/reporting-scheme/index.html',
          name: 'Financial Reporting Schemes',
          type: 'product'
        }
      ],
      currentRouteComponent: null,
      isLoading: true,
      authorized: false,
      profile: null,
      selectedReport: null,
      currentKey: '',
      reports: [],
      items: [],
      fields: [],
      bckp: {
        availableFields: [],
      },
      app: {
        modal: [],
        availableFields: [],
        dimensionsTemplate: [],
        parentheticalsTemplate: []
      },
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, {
        value: 100,
        text: "Show a lot"
      }],
      selectedSegmentOrScenario: 'segment',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        item: {},
        title: '',
        content: '',
        action: '',
        form: {}
      },
      parentheticalsModal: {
        id: 'parentheticals-modal',
        item: {},
        title: '',
        content: '',
        action: '',
        form: {}
      },
      dimensionsModal: {
        id: 'dimensions-modal',
        item: {},
        title: '',
        content: '',
        action: '',
        form: {}
      },
      errorGetTable: '',
      showAlertAction: false,
      errorTextAction: '',
      modal: {
        show: false,
        id: 'html-modal',
        type: 'html',
        title: '',
        buttonText: 'Show modal',
        data: '',
        code: ''
      },
      activeXMLkey: '',
      adminList: [
        '4rework@gmail.com',
        '0x3Eff96c86651df9D5Ce5714ef3f598fcb1A140D8',
        'war21x3b@gmail.com',
        'perfectmile@gmail.com',
        'digitalfinancialreporting@gmail.com',
        '0xAAEe1469155929FB360976e7715cD42dca0FF6e9'
      ],
      errorsArrGenerate: null,
      itemsEnterInformationFragments: []
    }
  },
  computed: {
    itemsEnterInformation () {
      return [
        {
          text: 'General',
          value: 'General'
        },
        {
          text: 'Base information',
          value: 'BaseInformation'
        },
        {
          text: 'Terms',
          value: 'ReportElement'
        },
        {
          text: 'Labels',
          value: 'Labels'
        },
        {
          text: 'References',
          value: 'References'
        },
        {
          text: 'Structures',
          value: 'Structures'
        },
        {
          text: 'Associations',
          value: 'Associations'
        },
        {
          text: 'Rules',
          value: 'Rules'
        },
        {
          text: 'Facts',
          value: 'Facts'
        }
      ]
    },
    itemsAdminEnterInformation () {
      return [
        {
          text: 'List Reporting Schemes',
          value: 'ReportingSchemesList'
        },
        {
          text: 'List Data Types',
          value: 'DataTypesList'
        },
        {
          text: 'List Languages',
          value: 'LanguagesList'
        },
        {
          text: 'List Currency Codes',
          value: 'CurrencyCodesList'
        },
        {
          text: 'List Roles',
          value: 'RolesList'
        },
        {
          text: 'List Association Roles',
          value: 'AssociationRolesList'
        },
        {
          text: 'List Units',
          value: 'UnitsList'
        }
      ]
    },
    filterNames () {
      return this.fields.filter(obj => obj.filterable)
    },
    sortOptions () {
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return {
            text: f.label,
            value: f.key
          }
        })
    }
  },
  mounted() {
    this.totalRows = this.items.length
  },
  created () {
  },
  methods: {
    closeModal () {
      this.modal.show = false

      this.selectedFlowTable = ''
      this.listStructures = []
      this.listFlowBlocks = []
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    positionMobile(ev) {
      mobile_last_move = ev;
    },
    drag(ev) {
      if (ev.type === "touchstart") {
        mobile_item_selec = ev.target.closest(".drag-drawflow").getAttribute('data-node');
      } else {
        ev.dataTransfer.setData("node", ev.target.getAttribute('data-node'));
      }
    },
    drop(ev) {
      if (ev.type === "touchend") {
        var parentdrawflow = document.elementFromPoint( mobile_last_move.touches[0].clientX, mobile_last_move.touches[0].clientY).closest("#drawflow");
        if(parentdrawflow != null) {
          this.addNodeToDrawFlow(mobile_item_selec, mobile_last_move.touches[0].clientX, mobile_last_move.touches[0].clientY);
        }
        mobile_item_selec = '';
      } else {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("node");
        this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
      }
    },
    addNodeToDrawFlow(node, pos_x, pos_y) {
      if(this.editor.editor_mode === 'fixed') {
        return false;
      }
      pos_x = pos_x * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().x * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)));
      pos_y = pos_y * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().y * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)));

      const nodeObj = JSON.parse(node)
      let selected = ''

      var nodeHTML = `
          <div>
            <div class="title-box">${nodeObj.name}</div>
            <div class="box">
              <p>${nodeObj.description}</p>`

      if (nodeObj.select && nodeObj.select.length > 0) {
        nodeHTML += `<select df-select>`
        nodeObj.select.forEach((opt) => {
          nodeHTML += `<option value="${opt.key}">${opt.value}</option>`
          if (opt.selected === 'true') {
            selected = opt.key
          }
        })
        nodeHTML += `</select>`
      }

      nodeHTML += `</div>
        </div>
      `;
      this.editor.addNode(nodeObj.name, 1, 1, pos_x, pos_y, nodeObj.id, { "select": selected}, nodeHTML );
    },
    initDrawflow () {
      const self = this

      var id = document.getElementById("drawflow");

      this.editor = new Drawflow(id, Vue, this);
      this.editor.reroute = true;
      this.editor.start();
      const defaultDrawflowData = {
        "drawflow": {
          "Home": {
            "data": {}
          }
        }
      }
      this.editor.import(defaultDrawflowData)

      var elements = document.getElementsByClassName('drag-drawflow');
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('touchend', self.drop, false);
        elements[i].addEventListener('touchmove', self.positionMobile, false);
        elements[i].addEventListener('touchstart', self.drag, false );
      }
    },
    showCalculationConstructor () {
      this.isLoading = false
      this.listStructures = []

      axios.get(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/Structures`)
        .then(response => {
          this.showModal({
            type: 'flow',
            data: '',
            code: '',
            keys: null
          })

          this.$nextTick(() => {
            this.initDrawflow()
          })

          response.data.items.forEach(obj => {
            this.listStructures.push({
              value: obj.NetworkIdentifier,
              text: obj.NetworkTitle
            })
          })

          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    changeFlowTable () {
      this.isLoading = false
      this.listFlowBlocks = []
      const defaultDrawflowData = {
        "Home": {
          "data": {}
        }
      }
      this.editor.import({
        "drawflow": defaultDrawflowData
      })

      axios.get(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/AssociationsFlow/${this.selectedFlowTable}`)
        .then(response => {
          this.listFlowBlocks = response.data?.fields || []
          let drawflowData = response.data?.drawflow || defaultDrawflowData

          if (Array.isArray(drawflowData.Home.data)) {
            let arrObjs = []
            drawflowData.Home.data.forEach(obj => {
              arrObjs.push(obj)
            })
            drawflowData.Home.data = {}

            arrObjs.forEach(obj => {
              const key = Object.keys(obj)[0]
              drawflowData.Home.data[key] = obj[key]
            });
          }

          console.log(drawflowData)

          this.editor.import({
            "drawflow": drawflowData
          })
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    saveFlow () {
      this.isLoading = true
      let data = this.editor.export()

      axios.post(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/AssociationsFlow/${this.selectedFlowTable}`, data)
        .then(response => {
          console.log(response)
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    changeArea (newArea) {
      this.selectedArea = newArea
      this.updateUrl()
      this.isLoading = true

      this.currentKey = ''
      this.app.modal = []
      this.app.availableFields = []
      this.bckp.availableFields = []
      this.app.dimensionsTemplate = []
      this.app.parentheticalsTemplate = []
      this.infoModal.form = {}

      if (this.selectedArea === 'fragments') {
        this.getFragmentsNav()
      } else {
        this.isLoading = false
      }
    },
    getFragmentsNav () {
      this.itemsEnterInformationFragments = []

      axios.get(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/Structures`)
        .then(response => {
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.hasOwnProperty('error')) {
            this.errorGetTable = response.data.error
          // eslint-disable-next-line no-prototype-builtins
          } else if (response.data.hasOwnProperty('items')) {
            this.errorGetTable = ''
            const collection = response.data
            const items = collection.items
            this.items = items
            // eslint-disable-next-line no-prototype-builtins
            if (collection.hasOwnProperty('app')) {
              // eslint-disable-next-line no-prototype-builtins
              if (collection.app.hasOwnProperty('modal')) {
                this.app.modal = collection.app.modal
              }
              // eslint-disable-next-line no-prototype-builtins
              if (collection.app.hasOwnProperty('available-fields')) {
                this.app.availableFields = collection.app['available-fields']
                this.app.availableFields.forEach((obj) => {
                  this.bckp.availableFields.push(JSON.parse(JSON.stringify(obj)))
                })
              }

              this.app.availableFields.forEach((field) => {
                if (field.hasOwnProperty('options')) {
                  if (field.options.length > 0) {
                    field.options = [...new Map(field.options.map(item => [item['value'], item])).values()]
                  }
                }
              })
            }
            this.totalRows = items.length
          } else {
            this.errorGetTable = 'Error or empty mock data'
          }
          this.isLoading = false
        })
        .then(() => {
          this.items.forEach(obj => {
            this.itemsEnterInformationFragments.push({
              value: obj.NetworkIdentifier,
              text: obj.NetworkTitle
            })
          })

          this.fields = []
          this.items = []
        })
        .catch(error => {
          this.errorGetTable = error.toString()
          console.log(error)
          this.isLoading = false
        })
    },
    buildErrJson (err) {
      let html = JSON.stringify(err, null, "  ")
      return hljs.highlight(html, {language: 'json'}).value
    },
    showDetailAlert (err) {
      err.show = !err.show
    },
    formatAlerts (arr) {
      return arr.map((obj) => {
        return {
          ...obj,
          show: false
        }
      })
    },
    onContextDatepicker () {
      this.$forceUpdate()
    },
    getValidationState({ dirty, validated, valid = null }, obj = null, customValidation = false) {
      if (obj !== null && customValidation) {
        if (obj.validation) {
          const regexRule = new RegExp(obj.validation.rule)
          console.log(regexRule.test(this.infoModal.form[obj.name]))
          return regexRule.test(this.infoModal.form[obj.name])
        } else {
          return null
        }
      } else {
        return dirty || validated ? valid : null;
      }
    },
    getErrorValidation(state, obj) {
      // const valState = this.getValidationState(state)
      let msg = ''
      if (obj.validation) {
        msg = obj.validation.error
      }
      return msg
    },
    getRegexValidationRule (obj) {
      let rule = {}
      if (obj.validation) {
        // rule = JSON.parse(obj.validation.rule)
        rule = {
          regex: new RegExp(obj.validation.rule)
        }
      }
      return rule
    },
    checkUserRights () {
      if (this.itemsAdminEnterInformation.find(obj => obj.value === this.currentKey) !== undefined) {
        if (this.profile) {
          if (this.profile.metaMaskAddress) {
            return this.adminList.indexOf(this.profile.metaMaskAddress) >= 0
          }
    
          if (this.profile.email) {
            return this.adminList.indexOf(this.profile.email) >= 0
          }
        }
  
        return false
      }

      return true
    },
    getCurrentReportWithType () {
      if (this.itemsAdminEnterInformation.find(obj => obj.value === this.currentKey)) {
        return 'administration'
      }

      return this.selectedReport?.value
    },
    getCuurentTableName () {
      let currentTable = this.itemsEnterInformation.find((obj) => {
        return obj.value === this.currentKey
      })

      if (currentTable === undefined) {
        currentTable = this.itemsAdminEnterInformation.find((obj) => {
          return obj.value === this.currentKey
        })
      }


      return currentTable ? currentTable.text : ''
    },
    onShownDialog () {
      this.$refs.dialog.focus()
    },
    onCancelDialog () {
      this.dialogState = false
    },
    onOKDialog () {
      this.isLoading = true
      axios.delete(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${this.currentKey}/clear`)
        .then(response => {
          if (response.data.success) {
            this.dialogState = false
            this.isLoading = false
            location.reload()
          }
        })
        .catch(error => {
          console.log(error.response)
          this.dialogState = false
          this.isLoading = false
        })
    },
    changeRoute (obj) {
      if (obj.path === '/') {
        this.selectedReport = null
        this.reports = []
        this.currentKey = ''
        this.currentRouteComponent = null
        this.getReports()
        history.pushState(
          {}, null, location.origin + obj.path
        )
      } else {
        if (obj.component) {
          this.currentRouteComponent = obj.component
          let path = location.origin + obj.path
          if (obj.path === '/esef' && this.selectedReport !== null) {
            path += `?id=${this.selectedReport.value}`
          }
          history.pushState(
            {}, null, path
          )
        } else {
          let link = document.createElement('a')
          link.target = '_blank'
          link.href = obj.path
          link.click()
        }
      }

    },
    updateUrl (type = '') {
      let path = ''

      if (this.selectedReport || type.length) {
        path += `/${this.getCurrentReportWithType()}`
      }

      if (this.currentKey.length) {
        path += `/${this.currentKey}`
      }

      history.pushState(
        {}, null, location.origin + path
      )
    },
    parseUrlAndGetData () {
      const checkInRoutes = this.routes.find(obj => obj.path === location.pathname)

      if (checkInRoutes !== undefined && checkInRoutes.path !== '/') {
        this.currentRouteComponent = checkInRoutes.component
      } else {
        this.getReports()
          .then(() => {
            const arrPaths = location.pathname.split('/').filter(el => el.length)
    
            if (arrPaths.length) {
              if (arrPaths.length >= 1) {
                if (arrPaths[0] !== 'administration') {
                  this.selectedReport = this.reports.find(obj => obj.value === arrPaths[0])
                }
              }
    
              if (arrPaths.length >= 2) {
                this.currentKey = arrPaths[1]
                if (arrPaths[0] === 'administration') {
                  this.getData(this.currentKey, 'administration')
                } else {
                  this.getData(this.currentKey)
                }
              }
            }
          })
      }
    },
    changeFile (evt) {
			const files = evt.target.files;
			if(files && files[0]) this.getJson(files[0]);
		},
    getJson (file) {
			/* Boilerplate to set up FileReader */
			const reader = new FileReader();
			reader.onload = (e) => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, {type:'binary'});
				/* Get first worksheet */
				const wsname = wb.SheetNames[0];
				const ws = wb.Sheets[wsname];
				/* Convert array of arrays */
				const data = XLSX.utils.sheet_to_json(ws, {header:1})

        const header = data[0]
        const dataObjs = []

        data.forEach((row, i) => {
          if (i > 0) {
            let obj = {}

            header.forEach((title, j) => {
              obj[title] = row[j]
            });

            dataObjs.push(obj)
          }
        });

        this.infoModal.content = dataObjs
        this.infoModal.form = dataObjs
			};
			reader.readAsBinaryString(file);
		},
    downloadTableXLSX () {
      let workbook = XLSX.utils.book_new();
      const headerCloneFn = function(data) {
        return data.map(x => {
          const obj = {
            ...x
          }

          return obj.name
        })
      }
      const itemsCloneFn = function(data) {
        return data.map(x => {
          const obj = {
            ...x
          }
          delete obj.id
          delete obj._showDetails

          return obj
        })
      }

      const header = headerCloneFn(this.app.availableFields)
      const items = itemsCloneFn(this.items)

      const ws = XLSX.utils.json_to_sheet(
        items,
        {
          header: header
        }
      )

      XLSX.utils.book_append_sheet(workbook, ws, this.currentKey)

      return XLSX.writeFile(workbook, `${this.selectedReport.text} - ${this.currentKey}.xlsx`)
    },
    loginSuccess () {
      this.authorized = true
      console.log('loginSuccess')

      axios.interceptors.request.use((config) => {
        if (this.profile.metaMaskAddress) {
          config.headers.UserMetaMaskAddress = this.profile.metaMaskAddress
        } else {
          config.headers.UserEmail = this.profile.email
        }

        return config;
      });

      this.parseUrlAndGetData()
      this.isLoading = false
    },
    handlerLogout () {
      localStorage.setItem('metaMaskAddress', '')
      // eslint-disable-next-line no-undef
      const auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
          console.log('User signed out.')
          this.authorized = false
          this.profile = null
          this.selectedReport = null
          this.reports = []
          this.currentKey = ''
          history.pushState(
            {}, null, location.origin
          )
      })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.app_offset != undefined) return `table-row-offset--${item.app_offset}`
    },
    getFieldLabelByKey (key) {
      return this.fields.find(el => el.key === key).label
    },
    changeTaxonomy () {
      console.log('change taxonomy')
      this.selectedReport = null
      this.reports = []
      this.currentKey = ''
      this.updateUrl()
      this.getReports()
    },
    changeReport () {
      console.log('change report')
      this.currentKey = ''
      this.updateUrl()

      if (this.selectedArea === 'fragments') {
        this.getFragmentsNav()
      }
    },
    onOkInfoModal () {
      if (this.infoModal.action === 'edit') {

        let formData = this.infoModal.form
        if (Object.keys(this.dimensionsModal.form).length) {
          formData.dimensions = []
          this.dimensionsModal.dimensions.forEach(arr => {
            let obj = {}

            arr.forEach(el => {
              if (this.dimensionsModal.form[el.name]) {
                let arrName = el.name.split('-')
                obj[arrName[0]] = this.dimensionsModal.form[el.name]
                obj.dimension_id = arrName[1]
              }
            })

            if (Object.keys(obj).length) {
              formData.dimensions.push(obj)
            }
          })
        }
        if (Object.keys(this.parentheticalsModal.form).length) {
          formData.parentheticals = []
          this.parentheticalsModal.parentheticals.forEach(arr => {
            let obj = {}

            arr.forEach(el => {
              if (this.parentheticalsModal.form[el.name]) {
                let arrName = el.name.split('-')
                obj[arrName[0]] = this.parentheticalsModal.form[el.name]
                obj.parenthetical_id = arrName[1]
              }
            })

            if (Object.keys(obj).length) {
              formData.parentheticals.push(obj)
            }
          })
        }

        axios.patch(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${this.currentKey}/${this.infoModal.item.id}`, formData)
          .then(response => {
            if (!response.data.success) {
              this.showAlertAction = true
              this.errorTextAction = response.data.error
            } else {
              this.getData(this.currentKey)
              this.$bvModal.hide('info-modal')
            }
            this.isLoading = false
          })
          .catch(error => {
            console.log(error.response)
            this.isLoading = false
          })
      }

      if (this.infoModal.action === 'delete') {
        axios.delete(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${this.currentKey}/${this.infoModal.item.id}`)
          .then(response => {
            if (!response.data.success) {
              this.showAlertAction = true
              this.errorTextAction = response.data.error
            } else {
              this.getData(this.currentKey)
              this.$bvModal.hide('info-modal')
            }
            this.isLoading = false
          })
          .catch(error => {
            console.log(error.response)
            this.isLoading = false
          })
      }

      if (this.infoModal.action === 'delete-report') {
        axios.delete(`https://esef.luca.report/api/reports/${this.getCurrentReportWithType()}`)
        // axios.delete(`/api/reports/${this.getCurrentReportWithType()}`)
          .then(response => {
            if (!response.data.success) {
              this.showAlertAction = true
              this.errorTextAction = response.data.error
            } else {
              this.selectedReport = null
              this.currentKey = ''
              this.getReports()
              this.updateUrl()
              this.$bvModal.hide('info-modal')
            }

            this.updateUrl()
            this.isLoading = false
          })
          .catch(error => {
            console.log(error.response)
            this.isLoading = false
          })
      }

      if (this.infoModal.action === 'add') {
        let formIsValid = true
        if (this.$refs.observer !== undefined && this.$refs.observer.fields) {
          Object.keys(this.$refs.observer.fields).forEach(key => {
            console.log(`${this.$refs.observer.fields[key].id} - ${this.$refs.observer.fields[key].valid}`)
            if (!this.$refs.observer.fields[key].valid) {
              formIsValid = false
            }
          });

          if (this.$refs.observer.$el.querySelectorAll('.form-group--state-false').length > 0) {
            formIsValid = false
          }
        }

        if (formIsValid) {
          if (this.currentKey !== 'General') {
            delete(this.infoModal.form.id)
          }
          let formData = this.infoModal.form
          if (Object.keys(this.dimensionsModal.form).length) {
            formData.dimensions = []
            this.dimensionsModal.dimensions.forEach(arr => {
              let obj = {}

              arr.forEach(el => {
                if (this.dimensionsModal.form[el.name]) {
                  let arrName = el.name.split('-')
                  obj[arrName[0]] = this.dimensionsModal.form[el.name]
                  obj.dimension_id = arrName[1]
                }
              })

              if (Object.keys(obj).length) {
                formData.dimensions.push(obj)
              }
            })
          }
          if (Object.keys(this.parentheticalsModal.form).length) {
            formData.parentheticals = []
            this.parentheticalsModal.parentheticals.forEach(arr => {
              let obj = {}

              arr.forEach(el => {
                if (this.parentheticalsModal.form[el.name]) {
                  let arrName = el.name.split('-')
                  obj[arrName[0]] = this.parentheticalsModal.form[el.name]
                  obj.dimension_id = arrName[1]
                }
              })

              if (Object.keys(obj).length) {
                formData.parentheticals.push(obj)
              }
            })
          }
          axios.post(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${this.currentKey}`, formData)
            .then(response => {
              if (!response.data.success) {
                this.showAlertAction = true
                this.errorTextAction = response.data.error
              } else {
                this.getData(this.currentKey)
                this.$bvModal.hide('info-modal')
              }
              this.isLoading = false
            })
            .catch(error => {
              console.log(error.response)
              this.isLoading = false
            })
        }
      }

      if (this.infoModal.action === 'new-report') {
        axios.post(`https://esef.luca.report/api/reports`, this.infoModal.form)
        // axios.post(`/api/reports`, this.infoModal.form)
          .then(response => {
            if (!response.data.success) {
              this.showAlertAction = true
              this.errorTextAction = response.data.error
            } else {
              this.getReports(this.infoModal.form.newReportName)

              if (this.selectedArea === 'fragments') {
                this.getFragmentsNav()
              }

              this.$bvModal.hide('info-modal')
            }
            this.isLoading = false
          })
          .catch(error => {
            console.log(error.response)
            this.isLoading = false
          })
      }

      if (this.infoModal.action === 'upload') {
        axios.post(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${this.currentKey}/upload`, this.infoModal.form)
          .then(response => {
            if (!response.data.success) {
              this.showAlertAction = true
              this.errorTextAction = response.data.error
            } else {
              this.$bvModal.hide('info-modal')
            }
            this.isLoading = false
            location.reload()
          })
          .catch(error => {
            console.log(error.response)
            this.isLoading = false
          })
      }

      if (this.infoModal.action === 'upload-dimensions') {
        axios.post(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${this.currentKey}/UploadDimensions`, this.infoModal.form)
          .then(response => {
            if (!response.data.success) {
              this.showAlertAction = true
              this.errorTextAction = response.data.error
            } else {
              this.$bvModal.hide('info-modal')
            }
            this.isLoading = false
            location.reload()
          })
          .catch(error => {
            console.log(error.response)
            this.isLoading = false
          })
      }

      if (this.infoModal.action === 'upload-parentheticals') {
        axios.post(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${this.currentKey}/UploadParentheticals`, this.infoModal.form)
          .then(response => {
            if (!response.data.success) {
              this.showAlertAction = true
              this.errorTextAction = response.data.error
            } else {
              this.$bvModal.hide('info-modal')
            }
            this.isLoading = false
            location.reload()
          })
          .catch(error => {
            console.log(error.response)
            this.isLoading = false
          })
      }
    },
    getReports (selectReport = null) {
      return new Promise((resolve, reject) => {
        axios.get(`https://esef.luca.report/api/reports`)
        // axios.get(`/api/reports`)
          .then(response => {
            this.reports = response.data

            if (selectReport) {
              const rep = this.reports.find(obj => obj.text === selectReport)
              if (rep !== undefined) {
                this.selectedReport = rep

                if (this.currentKey.length) {
                  this.getData(this.currentKey)
                }

                this.updateUrl()
              }
            }

            this.isLoading = false
            resolve()
          })
          .catch(error => {
            console.log(error.response)
            reject(error)
            this.isLoading = false
          })
      })
    },
    buildParentheticalArr (str) {
      let arr = []
      let strF = str

      if (typeof str === 'string') {
        strF = JSON.parse(str.replaceAll("'", '"'))
      }

      strF = JSON.parse(JSON.stringify(strF))

      strF.forEach((obj) => {
        delete obj.parenthetical_id
        arr.push(obj)
      })

      return arr
    },
    buildDimensionArr (str) {
      let arr = []
      let strF = str

      if (typeof str === 'string') {
        strF = JSON.parse(str.replaceAll("'", '"'))
      }

      strF = JSON.parse(JSON.stringify(strF))

      strF.forEach((obj) => {
        delete obj.dimension_id
        arr.push(obj)
      })

      return arr
    },
    getData (key, type = '') {
      this.showTree = false
      this.showPivot = false
      this.isLoading = true
      this.currentKey = key
      this.app.modal = []
      this.app.availableFields = []
      this.bckp.availableFields = []
      this.app.dimensionsTemplate = []
      this.app.parentheticalsTemplate = []
      this.infoModal.form = {}

      if (type.length === 0 || type === 'administration') {
        this.updateUrl(type)
        this.perPage = 10
      }

      // this.items = []
      // this.fields = []

      var keyRequest = this.currentKey

      if (key === 'General') {
        this.infoModal.action = 'add'
      }

      if (type.length > 0 && type !== 'administration') {
        keyRequest = `${this.currentKey}/${type}`

        this.selectedTabFragments = type

        // if (this.selectedTabFragments === 'ModelStructure') {
        //   keyRequest = `${this.currentKey}/Rendering`
        // }

        this.perPage = 0
      }

      axios.get(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${keyRequest}`)
        .then(response => {
          // eslint-disable-next-line no-prototype-builtins
          if (response.data.hasOwnProperty('error')) {
            this.errorGetTable = response.data.error
          // eslint-disable-next-line no-prototype-builtins
          } else if (response.data.hasOwnProperty('items')) {
            this.errorGetTable = ''
            const collection = response.data

            const fields = collection.fields
            const items = collection.items

            if (type.length === 0 || type === 'administration') {
              fields.push(
                {
                  key: 'actions',
                  label: 'Actions'
                }
              )
            }

            this.fields = fields
            this.items = items

            if ((this.selectedTabFragments === 'Rendering') && type.length > 0 && type !== 'administration') {
              this.showPivot = true
            }

            if ((this.selectedTabFragments === 'ModelStructure' || this.selectedTabFragments === 'BusinessRulesStructure') && type.length > 0 && type !== 'administration') {
              this.showTree = true

              this.fields.unshift(
                {
                  key: 'expand',
                  label: '...'
                }
              )
            }

            // eslint-disable-next-line no-prototype-builtins
            if (collection.hasOwnProperty('app')) {
              // eslint-disable-next-line no-prototype-builtins
              if (collection.app.hasOwnProperty('modal')) {
                this.app.modal = collection.app.modal
              }
              // eslint-disable-next-line no-prototype-builtins
              if (collection.app.hasOwnProperty('available-fields')) {
                this.app.availableFields = collection.app['available-fields']
                this.app.availableFields.forEach((obj) => {
                  this.bckp.availableFields.push(JSON.parse(JSON.stringify(obj)))
                })
              }

              // eslint-disable-next-line no-prototype-builtins
              if (collection.app.hasOwnProperty('dimensions-template')) {
                this.app.dimensionsTemplate = collection.app['dimensions-template']
              }
              // eslint-disable-next-line no-prototype-builtins
              if (collection.app.hasOwnProperty('parentheticals-template')) {
                this.app.parentheticalsTemplate = collection.app['parentheticals-template']
              }

              if (key === 'General') {
                this.app.availableFields.forEach(field => {
                  if (field.value) {
                    this.infoModal.form[field.name] = field.value
                  }
                });
              }

              this.app.availableFields.forEach((field) => {
                if (field.hasOwnProperty('options')) {
                  if (field.options.length > 0) {
                    field.options = [...new Map(field.options.map(item => [item['value'], item])).values()]
                  }
                }
              })
            }
            this.totalRows = items.length
          } else {
            this.errorGetTable = 'Error or empty mock data'
          }
          this.isLoading = false
        })
        .catch(error => {
          this.errorGetTable = error.toString()
          console.log(error)
          this.isLoading = false
        })
    },
    showModal (data) {
      this.isLoading = true

      this.modal.type = data.type
      this.modal.data = data.data
      this.modal.title = data.title ? data.title : ''

      if (data.keys) {
        this.modal.keys = data.keys
      }

      this.modal.show = true
      this.isLoading = false
    },
    downloadAllXMLsInZIP () {
      this.isLoading = true
      const zip = new JSZip()
      const that = this

      async function processArray () {
        for (const obj of that.modal.keys) {
          let keyWithParams = obj.value

          if (obj.value === 'GenerateINS' || obj.value === 'GenerateDEF') {
            keyWithParams = `${obj.value}?contextElement=${that.selectedSegmentOrScenario}`
          }
          const response = await axios.get(`https://esef.luca.report/api/${that.getCurrentReportWithType()}/${keyWithParams}`)

          zip.file(obj.fileName, response.data.raw)
        }
        console.log('Done!');
      }

      processArray().then(() => {
        zip
          .generateAsync({
            type: 'blob'
          })
          .then((blob) => {
            FileSaver.saveAs(blob, 'all-reports.zip');
            this.isLoading = false
          }, function (err) {
            console.log(err)
          })
      })

    },
    downloadFileValidation () {
      let html = `<html xmlns="http://www.w3.org/1999/xhtml" dir="ltr">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <style>.hljs{display:block;overflow-x:auto;padding:.5em;color:#383a42;background:#fafafa}.hljs-comment,.hljs-quote{color:#a0a1a7;font-style:italic}.hljs-doctag,.hljs-formula,.hljs-keyword{color:#a626a4}.hljs-deletion,.hljs-name,.hljs-section,.hljs-selector-tag,.hljs-subst{color:#e45649}.hljs-literal{color:#0184bb}.hljs-addition,.hljs-attribute,.hljs-meta-string,.hljs-regexp,.hljs-string{color:#50a14f}.hljs-built_in,.hljs-class .hljs-title{color:#c18401}.hljs-attr,.hljs-number,.hljs-selector-attr,.hljs-selector-class,.hljs-selector-pseudo,.hljs-template-variable,.hljs-type,.hljs-variable{color:#986801}.hljs-bullet,.hljs-link,.hljs-meta,.hljs-selector-id,.hljs-symbol,.hljs-title{color:#4078f2}.hljs-emphasis{font-style:italic}.hljs-strong{font-weight:700}.hljs-link{text-decoration:underline}details{background:#eee;margin-bottom:1rem;padding:.5rem 1rem}details summary{cursor:pointer}details summary>*{display:inline}details.info{background-color:#cff4fc;border-left:5px solid #b6effb}details.info summary{color:#055160}details.error{background-color:#b22633;border-left:5px solid #b22633}details.error summary{color:#fff}details.warning{background-color:#fff3cd;border-left:5px solid #ffeeba}details.warning summary{color:#664d03}details pre{background-color:#fff;padding:1rem;overflow:auto}details.valid{background-color:#09912a;border-left:5px solid #09912a}details.valid summary{color:#fff}details.not_applicable{background-color:#c6ced0;border-left:5px solid #b9b9b9}details.not_applicable summary{color:#222}</style>
  </head>
  <body>`
      let data = ''
      this.validationJSON.forEach(obj => {
        data += `<details class="${obj.level}">
<summary>${obj.message.text}</summary>
<pre><code>${this.buildErrJson(obj)}</code></pre>
</details>`
      });

    html += `
    ${data}
  </body>
</html>
      `
      let link = document.createElement('a')
      link.download = `validation.html`
      const blob = new Blob([html], {type: 'text/html'})
      link.href = window.URL.createObjectURL(blob)
      link.click()
    },
    downloadFileXML () {
      const currentKey = this.modal.keys.find(obj => obj.value === this.activeXMLkey)
      let link = document.createElement('a')
      link.download = `${currentKey.fileName}`
      const blob = new Blob([this.modal.data], {type: 'text/xml'})
      link.href = window.URL.createObjectURL(blob)
      link.click()
    },
    getXMLs () {
      axios.get(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/GetXMLFiles`)
        .then(response => {
          this.showModal({
            type: 'code',
            data: '',
            code: '',
            keys: response.data.keys
          })

          this.generateXML(response.data.keys[0].value)

          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    },
    generateXML (key) {
      this.activeXMLkey = key
      this.errorsArrGenerate = null

      let keyWithParams = key

      if (key === 'GenerateINS' || key === 'GenerateDEF') {
        keyWithParams = `${key}?contextElement=${this.selectedSegmentOrScenario}`
      }

      axios.get(`https://esef.luca.report/api/${this.getCurrentReportWithType()}/${keyWithParams}`)
        .then(response => {
          let rawHTML = response.data.raw
          this.errorsArrGenerate = rawHTML.match(/(\[Errorcode.*])/gm)
          const html = hljs.highlight(rawHTML, {language: 'xml'}).value
          this.modal.data = response.data.raw
          this.modal.code = html
          this.$forceUpdate()
        })
        .catch(error => {
          console.log(error)
        })
    },
    chagneActiveVariantModalKey (key) {
      this.activeVariantModalkey = key
      if (key === 'validation') {
        this.getContentVaildationByKey(this.validationKeys[0].value)
      }
    },
    getContentVaildationByKey (key) {
      this.validationJSON = []
      this.activeValidationKey = key
      if (key === 'consistency') {
        this.isLoading = true
        axios.get(`https://esef.luca.report/api/validation/consistency/${this.getCurrentReportWithType()}`)
          .then(response => {
            this.validationJSON = this.formatAlerts(response.data.log)
            this.$forceUpdate()
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
      }
      if (key === 'general') {
        this.isLoading = true
        axios.get(`https://esef.luca.report/api/validation/general/${this.getCurrentReportWithType()}`)
          .then(response => {
            this.validationJSON = this.formatAlerts(response.data.log)
            this.$forceUpdate()
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
      }
      if (key === 'arelle') {
        this.isLoading = true
        axios.get(`https://esef.luca.report/api/validation/arelle/${this.getCurrentReportWithType()}`)
          .then(response => {
            this.validationJSON = this.formatAlerts(response.data.log)
            this.$forceUpdate()
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
      }
    },
    resetModal () {
      this.modal = {
        show: false,
        id: 'html-modal',
        type: 'html',
        title: '',
        buttonText: 'Show modal',
        data: ''
      }

      this.validationJSON = []
      this.activeVariantModalkey = 'code'

      this.showAlertAction = false
    },
    info(item, index, button, action) {
      this.infoModal.action = action

      if (action === 'delete') {
        this.infoModal.title = 'Are you sure you want to delete this row?'
        this.infoModal.item = item
        this.infoModal.content = JSON.stringify(item, null, 2)
      }

      if (action === 'delete-report') {
        const reportName = this.reports.find((obj) => {
          return obj.value === this.selectedReport.value
        })
        this.infoModal.title = `Are you sure you want to delete ${reportName.text}?`
        this.infoModal.content = ''
      }

      if (action === 'upload-dimensions') {
        this.infoModal.title = 'Load Dimensions from Excel'
        this.infoModal.content = ''
      }

      if (action === 'upload-parentheticals') {
        this.infoModal.title = 'Load Parentheticals from Excel'
        this.infoModal.content = ''
      }

      if (action === 'upload') {
        this.infoModal.title = 'Load data from Excel'
        this.infoModal.content = ''
      }

      if (action === 'new-report') {
        this.infoModal.title = 'Add new report'
        this.infoModal.content = ''

        this.infoModal.form['newReportName'] = ''
      }

      if (action === 'edit') {
        this.infoModal.title = 'Edit row'
        this.infoModal.item = item

        if (item.Dimensions !== undefined) {
          let dimensArr = item.Dimensions
          let dimensObj = {}
          this.dimensionsModal.dimensions = []
          if (dimensArr == null) dimensArr = []
          if (typeof dimensArr === 'string') {
            dimensArr = JSON.parse(dimensArr.replaceAll("'", '"'))
          }

          dimensArr.forEach(obj => {
            let arr = []
            this.app.dimensionsTemplate.forEach(templateObj => {
              arr.push({
                ...templateObj,
                name: `${templateObj.name}-${obj.dimension_id}`
              })
            })
            this.dimensionsModal.dimensions.push(arr)
            Object.keys(obj).forEach(key => {
              if (key !== 'dimension_id') {
                dimensObj[`${key}-${obj.dimension_id}`] = obj[key]
              }
            })
          })

          this.dimensionsModal.form = dimensObj
        }

        if (item.Parentheticals !== undefined) {
          let parenthArr = item.Parentheticals
          let parenthObj = {}
          this.parentheticalsModal.parentheticals = []
          if (parenthArr == null) parenthArr = []
          if (typeof parenthArr === 'string') {
            parenthArr = JSON.parse(parenthArr.replaceAll("'", '"'))
          }

          parenthArr.forEach(obj => {
            let arr = []
            this.app.parentheticalsTemplate.forEach(templateObj => {
              arr.push({
                ...templateObj,
                name: `${templateObj.name}-${obj.parenthetical_id}`
              })
            })
            this.parentheticalsModal.parentheticals.push(arr)
            Object.keys(obj).forEach(key => {
              if (key !== 'parenthetical_id') {
                parenthObj[`${key}-${obj.parenthetical_id}`] = obj[key]
              }
            })
          })

          this.parentheticalsModal.form = parenthObj
        }

        this.app.availableFields.forEach(obj => {
          if (obj.name.indexOf('app_') < 0) {
            this.infoModal.form[obj.name] = item[obj.name]
          }
        });
      }

      if (action === 'add') {
        this.infoModal.title = 'Add'
        this.infoModal.item = {}

        if (this.app.dimensionsTemplate) {
          this.dimensionsModal.dimensions = []
        }

        if (this.app.parentheticalsTemplate) {
          this.parentheticalsModal.parentheticals = []
        }

        this.app.availableFields.forEach(obj => {
          this.infoModal.item[obj.name] = '';

          if (obj.name.indexOf('app_') < 0) {
            this.infoModal.form[obj.name] = ''
          }
        })
      }

      this.$root.$emit('bv::show::modal', this.infoModal.id, button)

      this.$nextTick(() => {
        this.app.availableFields.forEach(obj => {
          this.checkRelatedFields(obj)
        })
      })
    },
    showAlert (text) {
      alert(text)
    },
    openDimensionsModal (action) {
      this.dimensionsModal.action = action
      if (action === 'add') {
        this.dimensionsModal.title = 'Add dimensions'
      }
      if (action === 'edit') {
        this.dimensionsModal.title = 'Edit dimensions'
      }
      this.dimensionsModal.item = {}
      this.$root.$emit('bv::show::modal', this.dimensionsModal.id)
    },
    addDimension () {
      const curLength = this.dimensionsModal.dimensions.length
      let curPos = curLength

      if (curLength > 0) {
        curPos = parseInt(this.dimensionsModal.dimensions[curLength - 1][0].name.split('-')[1])
      }

      let objTemplate = []
      this.app.dimensionsTemplate.forEach(obj => {
        let objClone = { ...obj }
        objClone.name = `${objClone.name}-${curPos + 1}`
        objTemplate.push(objClone)
      })
      this.dimensionsModal.dimensions.push(objTemplate)
      this.$forceUpdate()
    },
    removeDimension (i) {
      this.dimensionsModal.dimensions = this.dimensionsModal.dimensions.filter(arr => {
        return arr[0].name.split('-')[1] !== i
      })
      this.app.dimensionsTemplate.forEach(obj => {
        delete this.dimensionsModal.form[`${obj.name}-${i}`]
      })
      this.$forceUpdate()
    },
    openParentheticalsModal (action) {
      this.parentheticalsModal.action = action
      if (action === 'add') {
        this.parentheticalsModal.title = 'Add parentheticals'
      }
      if (action === 'edit') {
        this.parentheticalsModal.title = 'Edit parentheticals'
      }
      this.parentheticalsModal.item = {}
      this.$root.$emit('bv::show::modal', this.parentheticalsModal.id)
    },
    addParenthetical () {
      const curLength = this.parentheticalsModal.parentheticals.length
      let curPos = curLength

      if (curLength > 0) {
        curPos = parseInt(this.parentheticalsModal.parentheticals[curLength - 1][0].name.split('-')[1])
      }

      let objTemplate = []
      this.app.parentheticalsTemplate.forEach(obj => {
        let objClone = { ...obj }
        objClone.name = `${objClone.name}-${curPos + 1}`
        objTemplate.push(objClone)
      })
      this.parentheticalsModal.parentheticals.push(objTemplate)
      this.$forceUpdate()
    },
    removeParenthetical (i) {
      this.parentheticalsModal.parentheticals = this.parentheticalsModal.parentheticals.filter(arr => {
        return arr[0].name.split('-')[1] !== i
      })
      this.app.parentheticalsTemplate.forEach(obj => {
        delete this.parentheticalsModal.form[`${obj.name}-${i}`]
      })
      this.$forceUpdate()
    },
    resetInfoModal() {
      this.app.availableFields = []
      this.bckp.availableFields.forEach((obj) => {
        this.app.availableFields.push(JSON.parse(JSON.stringify(obj)))
      })
      this.infoModal.title = ''
      this.infoModal.content = ''
      this.infoModal.action = ''
      this.infoModal.code = ''
      this.infoModal.item = {}
      this.infoModal.form = {}
      if (this.dimensionsModal.dimensions) {
        delete this.dimensionsModal.dimensions
        this.dimensionsModal.action = ''
        this.dimensionsModal.form = {}
      }
      if (this.parentheticalsModal.parentheticals) {
        delete this.parentheticalsModal.parentheticals
        this.parentheticalsModal.action = ''
        this.parentheticalsModal.form = {}
      }
      this.showAlertAction = false
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onInputSelectAjax (search, loading, obj) {
      if(search.length > 2) {
        loading(true);

        axios.get(`https://esef.luca.report${obj.ajax}?q=${escape(search)}`)
          .then(response => {
            console.log(response);
            if (response.data.dictionary) {
              obj.dictionary = response.data.dictionary
            }
            if (response.data.items) {
              obj.options = response.data.items.map(item => {
                return {
                  text: item.name,
                  value: item.name,
                  ...item
                }
              })
            }
            loading(false)
            this.$forceUpdate()
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    },
    checkRelatedFields(obj) {
      const curVal = this.infoModal.form[obj.name]

      if (obj !== undefined && obj.options && obj.dictionary) {
        const optObj = obj.options.find(optObj => optObj.name === curVal)
        if (optObj !== undefined) {
          Object.keys(optObj).forEach(key => {
            const trueKey = obj.dictionary[key] || key
            const trueVal = obj.dictionary[optObj[key]] || optObj[key]

            // eslint-disable-next-line no-prototype-builtins
            if (this.infoModal.form.hasOwnProperty(trueKey)) {
              this.infoModal.form[trueKey] = trueVal
            }
          })
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty('relatedInitialValue')) {
        const curVal = this.infoModal.form[obj.name]

        // eslint-disable-next-line no-prototype-builtins
        if (obj.relatedInitialValue.hasOwnProperty(curVal)) {
          obj.relatedInitialValue[curVal].forEach(el => {
            const nededObj = this.app.availableFields.find(fieldObj => fieldObj.name === el.name)
            if (nededObj !== undefined) {
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('type')) {
                nededObj.type = el.type
              }
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('options')) {
                nededObj.options = el.options
              }
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('editable')) {
                nededObj.editable = el.editable
              }
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('taggable')) {
                nededObj.taggable = el.taggable
              }
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('ajax')) {
                nededObj.ajax = el.ajax
              }
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('placeholder')) {
                nededObj.placeholder = el.placeholder
              }
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('validation')) {
                nededObj.validation = el.validation
              }
              // eslint-disable-next-line no-prototype-builtins
              if (el.hasOwnProperty('value')) {
                this.infoModal.form[el.name] = el.value
              } else {
                // this.infoModal.form[el.name] = ''
              }
            } else {
              this.app.availableFields.push(el)
            }
          })
          this.$forceUpdate()
        }
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style>
#app {
  font-family: 'Roboto', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  min-height: 100%;
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.25);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.spinner-border {
  width: 4rem;
  height: 4rem;
  border: 0.25em solid #0A58CA;
  border-right-color: transparent;
}
</style>
