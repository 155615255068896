<template>
    <div style="display: inline-block">
        <div @click="init()" style="height:50px;width:240px;" class="metamaskbtn abcRioButton abcRioButtonBlue">
          <div class="abcRioButtonContentWrapper">
            <div class="abcRioButtonIcon" style="padding:11px">
              <div style="width:26px;height:26px;"
                class="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18"
              >
                <svg xmlns:ev="http://www.w3.org/2001/xml-events" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 318.6 318.6" style="enable-background:new 0 0 318.6 318.6;" xml:space="preserve">
                  <polygon class="st0" points="274.1,35.5 174.6,109.4 193,65.8 "/>
                  <g>
                    <polygon class="st1" points="44.4,35.5 143.1,110.1 125.6,65.8  "/>
                    <polygon class="st1" points="238.3,206.8 211.8,247.4 268.5,263 284.8,207.7  "/>
                    <polygon class="st1" points="33.9,207.7 50.1,263 106.8,247.4 80.3,206.8  "/>
                    <polygon class="st1" points="103.6,138.2 87.8,162.1 144.1,164.6 142.1,104.1  "/>
                    <polygon class="st1" points="214.9,138.2 175.9,103.4 174.6,164.6 230.8,162.1  "/>
                    <polygon class="st1" points="106.8,247.4 140.6,230.9 111.4,208.1  "/>
                    <polygon class="st1" points="177.9,230.9 211.8,247.4 207.1,208.1  "/>
                  </g>
                  <g>
                    <polygon class="st2" points="211.8,247.4 177.9,230.9 180.6,253 180.3,262.3  "/>
                    <polygon class="st2" points="106.8,247.4 138.3,262.3 138.1,253 140.6,230.9  "/>
                  </g>
                  <polygon class="st3" points="138.8,193.5 110.6,185.2 130.5,176.1 "/>
                  <polygon class="st3" points="179.7,193.5 188,176.1 208,185.2 "/>
                  <g>
                    <polygon class="st4" points="106.8,247.4 111.6,206.8 80.3,207.7  "/>
                    <polygon class="st4" points="207,206.8 211.8,247.4 238.3,207.7  "/>
                    <polygon class="st4" points="230.8,162.1 174.6,164.6 179.8,193.5 188.1,176.1 208.1,185.2  "/>
                    <polygon class="st4" points="110.6,185.2 130.6,176.1 138.8,193.5 144.1,164.6 87.8,162.1  "/>
                  </g>
                  <g>
                    <polygon class="st5" points="87.8,162.1 111.4,208.1 110.6,185.2  "/>
                    <polygon class="st5" points="208.1,185.2 207.1,208.1 230.8,162.1  "/>
                    <polygon class="st5" points="144.1,164.6 138.8,193.5 145.4,227.6 146.9,182.7  "/>
                    <polygon class="st5" points="174.6,164.6 171.9,182.6 173.1,227.6 179.8,193.5  "/>
                  </g>
                  <polygon class="st6" points="179.8,193.5 173.1,227.6 177.9,230.9 207.1,208.1 208.1,185.2 "/>
                  <polygon class="st6" points="110.6,185.2 111.4,208.1 140.6,230.9 145.4,227.6 138.8,193.5 "/>
                  <polygon class="st7" points="180.3,262.3 180.6,253 178.1,250.8 140.4,250.8 138.1,253 138.3,262.3 106.8,247.4 117.8,256.4   140.1,271.9 178.4,271.9 200.8,256.4 211.8,247.4 "/>
                  <polygon class="st8" points="177.9,230.9 173.1,227.6 145.4,227.6 140.6,230.9 138.1,253 140.4,250.8 178.1,250.8 180.6,253 "/>
                  <g>
                    <polygon class="st9" points="278.3,114.2 286.8,73.4 274.1,35.5 177.9,106.9 214.9,138.2 267.2,153.5 278.8,140 273.8,136.4    281.8,129.1 275.6,124.3 283.6,118.2  "/>
                    <polygon class="st9" points="31.8,73.4 40.3,114.2 34.9,118.2 42.9,124.3 36.8,129.1 44.8,136.4 39.8,140 51.3,153.5 103.6,138.2    140.6,106.9 44.4,35.5  "/>
                  </g>
                  <polygon class="st6" points="267.2,153.5 214.9,138.2 230.8,162.1 207.1,208.1 238.3,207.7 284.8,207.7 "/>
                  <polygon class="st6" points="103.6,138.2 51.3,153.5 33.9,207.7 80.3,207.7 111.4,208.1 87.8,162.1 "/>
                  <polygon class="st6" points="174.6,164.6 177.9,106.9 193.1,65.8 125.6,65.8 140.6,106.9 144.1,164.6 145.3,182.8 145.4,227.6   173.1,227.6 173.3,182.8 "/>
                  </svg>
              </div>
            </div>
            <span style="font-size:16px;line-height:48px;" class="abcRioButtonContents">
              <span>Sign in with MetaMask</span>
            </span>
          </div>
        </div>
        <div id="vue-metamask"></div>
    </div>
</template>

<script>
import Web3 from "web3";

export default {
  props: {
    userMessage: {
      type: String,
      default: "Login in Luca",
    },
  },
  data() {
    return {
      web3: null,
      MetaMaskId: "1", // main net netID
      netID: "1", // user metamask id
      MetaMaskAddress: "", // user Address
      Web3Interval: null,
      AccountInterval: null,
      NetworkInterval: null,
      stateLog: null,
      isComplete: false,
      type: "INIT",
      MetamaskMsg: {
        LOAD_MATAMASK_WALLET_ERROR: "Loading metamask error, please try later",
        EMPTY_METAMASK_ACCOUNT:
          "Please log in to your metamask to continue with this app.",
        NETWORK_ERROR: "The connection is abnormal, please try again",
        METAMASK_NOT_INSTALL: "Please install metamask for this application",
        METAMASK_TEST_NET: "Currently not in the main network.",
        METAMASK_MAIN_NET: "Currently Main network",
        USER_DENIED_ACCOUNT_AUTHORIZATION: "User denied account authorization",
      },
    };
  },
  methods: {
    checkWeb3() {
      let web3 = window.web3;
      if (typeof web3 === "undefined") {
        this.web3 = null;
        this.Log(this.MetamaskMsg.METAMASK_NOT_INSTALL, "NO_INSTALL_METAMASK");
      }
    },
    checkAccounts() {
      if (this.web3 === null) return;
      this.web3.eth.getAccounts((err, accounts) => {
        console.log();

        if (err != null)
          return this.Log(this.MetamaskMsg.NETWORK_ERROR, "NETWORK_ERROR");
        if (accounts.length === 0) {
          this.MetaMaskAddress = "";
          this.Log(this.MetamaskMsg.EMPTY_METAMASK_ACCOUNT, "NO_LOGIN");
          return;
        }
        this.MetaMaskAddress = accounts[0]; // user Address
      });
    },
    checkNetWork() {
      try {
        // Main Network: 1
        // Ropsten Test Network: 3
        // Kovan Test Network: 42
        // Rinkeby Test Network: 4
        this.web3.eth.net.getId()
        .then(netId => {
          this.netID = netId
          if (this.MetaMaskAddress !== "" && netId === 1)
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "MAINNET");
          if (this.MetaMaskAddress !== "" && netId === 3)
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "ROPSTEN");
          if (this.MetaMaskAddress !== "" && netId === 42)
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "LOVAN");
          if (this.MetaMaskAddress !== "" && netId === 4)
            return this.Log(this.MetamaskMsg.METAMASK_TEST_NET, "RINKEBY");
          if (this.MetaMaskAddress !== "")
            this.Log(this.MetamaskMsg.METAMASK_MAIN_NET, "MAINNET");
        })
      } catch (err) {
        this.Log(this.MetamaskMsg.NETWORK_ERROR, "NETWORK_ERROR");
      }
    },
    Log(msg, type = "") {
      const letType = type;
      if (letType === this.type) return;
      const message = this.userMessage === "null" ? msg : this.userMessage;
      this.type = type;
      this.$emit("onComplete", {
        web3: this.web3,
        type,
        metaMaskAddress: this.MetaMaskAddress,
        message,
        netID: this.netID,
      });
    },
    web3TimerCheck(web3) {
      this.web3 = web3;
      this.checkAccounts();
      this.checkNetWork();
      this.Web3Interval = setInterval(() => this.checkWeb3(), 1000);
      this.AccountInterval = setInterval(() => this.checkAccounts(), 1000);
      this.NetworkInterval = setInterval(() => this.checkNetWork(), 1000);
    },
    async init () {
        if (window.ethereum) {
            window.web3 = new Web3(ethereum);
            try {
                await ethereum.enable();
                this.web3TimerCheck(window.web3);
            } catch (error) {
                this.Log(
                  this.MetamaskMsg.USER_DENIED_ACCOUNT_AUTHORIZATION,
                  "USER_DENIED_ACCOUNT_AUTHORIZATION"
                );
            }
        } else if (window.web3) {
            window.web3 = new Web3(web3.currentProvider);
            this.web3TimerCheck(window.web3);
        } else {
            this.web3 = null;
            this.Log(this.MetamaskMsg.METAMASK_NOT_INSTALL, "NO_INSTALL_METAMASK");
            console.error(
              "Non-Ethereum browser detected. You should consider trying MetaMask!"
            );
        }
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
#vue-metamask {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.st0{fill:#E2761B;stroke:#E2761B;stroke-linecap:round;stroke-linejoin:round;}
.st1{fill:#E4761B;stroke:#E4761B;stroke-linecap:round;stroke-linejoin:round;}
.st2{fill:#D7C1B3;stroke:#D7C1B3;stroke-linecap:round;stroke-linejoin:round;}
.st3{fill:#233447;stroke:#233447;stroke-linecap:round;stroke-linejoin:round;}
.st4{fill:#CD6116;stroke:#CD6116;stroke-linecap:round;stroke-linejoin:round;}
.st5{fill:#E4751F;stroke:#E4751F;stroke-linecap:round;stroke-linejoin:round;}
.st6{fill:#F6851B;stroke:#F6851B;stroke-linecap:round;stroke-linejoin:round;}
.st7{fill:#C0AD9E;stroke:#C0AD9E;stroke-linecap:round;stroke-linejoin:round;}
.st8{fill:#161616;stroke:#161616;stroke-linecap:round;stroke-linejoin:round;}
.st9{fill:#763D16;stroke:#763D16;stroke-linecap:round;stroke-linejoin:round;}

.metamaskbtn {
    background-color: #f6851a;
}

.metamaskbtn:hover {
    box-shadow: 0 0 3px 3px rgb(246 133 26 / 30%);
    background-color: #f6851a;
}
</style>